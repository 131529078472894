import React, { useContext } from "react";
import PFPromo from "./PFPromo";
import PropTypes from "prop-types";
import { Container } from "react-bootstrap";
import { AppContext } from "../../data/stores/app.store";
/**
 * PFPromos component maps the data for the websites promotions. 
 *   - It renders the header, image, image alt text, 
 *     button, button text, and button link.
 */

const PFPromos = (props) => {
  const { appData, market, setMarket } = useContext(AppContext);

  const whichUrl = (market === "fr" || market === "us" || market === "uk") ?
    appData.marketConfig[market] :
    appData.marketConfig['eu'];


  return (
    <Container>
      {props.data.map((promo, i) => {

        const link = promo.id === "RequestYourSamples" ? whichUrl.samplesURL :
          promo.id === "DiscoverInStores" ? whichUrl.storeURL :
            whichUrl;

        return (<PFPromo
          key={"perfumeFinderPromo" + i}
          id={promo.id}
          classes={promo.classes}
          header={promo.header}
          image={promo.image}
          link={link}
          actionText={promo.actionText}
          alt={promo.alt} />)
      })
      }
    </Container>
  );
}
PFPromos.propTypes = {
  promo: PropTypes.object,
}

export default PFPromos;