import React, {useContext}from "react";
import PropTypes from 'prop-types'
import { AppContext } from "../../data/stores/app.store";


const PFSlogan = (props) => {
    const {appData} = useContext(AppContext);
    return (
        <div className="slogan" id={appData?.semanticList?.brandTag}>
            <div className="slogan-line-one">
                {props.data?.slogan}
            </div>
            <div className="slogan-line-two">
                {props.data?.sloganSubText}
            </div>
        </div>
    );
};

PFSlogan.propTypes = {
  data: PropTypes.shape({
    slogan: PropTypes.string,
    sloganSubText: PropTypes.string
  }),

};

export default PFSlogan;