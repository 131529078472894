import React from "react";
import PropTypes from "prop-types";

/**
 * An Icon component *does something*. 
 */

export default function Icon(props) {
    return (
        <i className={"zmdi zmdi-" + props.variant + " " + (props.className?props.className:"")}></i>
    );
}

Icon.propTypes = {
    variant: PropTypes.string,
    className: PropTypes.string,
  }