// Fetch Data - Utilities to get data from apis
export const FetchData = (url,setData,handleData,setModifiedData, fallback, setRefreshData, dataPath, isModified,language, quizResults, handleSetConfirmed) => {
  let apiUrl = url;
  try {
    fetch(
      apiUrl,
      {
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin' : '*',
        'Connection':'keep-alive',
        // 'X-Accept-Language': language
      },
      method: "POST",
      mode: 'cors',
      body: JSON.stringify({
        language: language,
        quizResults: quizResults
      }),
      // cache: "no-cache",
    }
    ).then((response) => {
      if (response.status >= 200 && response.status <= 299) {
        return response.json();
      }else if(response.status === 403) {
        return setData && setData(fallback);
      }else{
        return setData && setData(fallback);
      }
    }).then((data)=>{
        if(data && data !== null){
          //API is list format - get results
          // If we have a tranformer function we transform the data and return newData
          data = data.data? data.data:data;
          const newData = handleData ? handleData(data):null;
          // If no transformer function use the passed through Set State funtion with the returned data
          setData && setData(data);
          // If we have a transformer function use the passed through Set State funtion  with transformed data
          (handleData && setModifiedData) && setModifiedData(newData);

          if (dataPath) {
          //  Set Data in LocalStorage for Caching
            const dateNow = Date.now();
            localStorage.setItem('d_expires', dateNow);
            isModified ? localStorage.setItem(dataPath, JSON.stringify(newData)):localStorage.setItem(dataPath,JSON.stringify(data));
            setRefreshData && setRefreshData(false);
          }
          handleSetConfirmed && handleSetConfirmed(true);
        } else {
          handleSetConfirmed && handleSetConfirmed(false);
        }
      }
    ).catch((error) => {
      console.log('Failed to fetch', error);
      handleSetConfirmed && handleSetConfirmed(false);
    });
  } catch (error) {
    console.log('Failed to fetch', error);
    handleSetConfirmed && handleSetConfirmed(false);
  }
};

export const JustFetch = (url) => {
  let apiUrl = url;
  return fetch(
    apiUrl,
    {
      method: "GET",
      mode: 'cors',
      cache: "no-cache",
      headers: {
        'Content-Type': 'application/json',
      }
    }
  ).then((response) => {
    return response.json();
  }).then((data)=>{
    return data;
  });
}