export const idConfig = {
// Landing Page 
    headerLogo: "FredericMalleLogo", //done
    languageSelection: "perfumeFinderLanguageSelections", //done
    mainHeader: "perfumeFinderHeader", //done
    miniHeader: "perfumeFinderSubHeader",//done
    genderSelector: "genderSelector", //done
    emaiEntry: "perfumeFinderEmailEntryForm", // done

//Wizard Step

    stepHeader: "perfumeFinderWizardSelectHeader", // done
    stepName: "perfumeFinderWizardSelectGroup", //done

// Navigation 

    navigationWrapper: "perfumeFinderNavigationWrapper", // done
    stepTitle: "perfumeFinderNavigationStepTitle", //done
    stepButton: "navigationStep", //done


// Results 
    yourStyleResult: "perfumeResultsHeader", //done
    restartButton: "perfumeFinderRestartButton", //done
    brandTag: "perfumeFinderMotto",  //done
    resultImageSelection: "perfumeFindeHereSelected", // done
    emailResult: "perfumeFinderActionEmailButton", //done

//Suggestions
    suggestionsHeader: "perfumeFinderRecommendationHeader", //done
    suggestionsID: "perfumeFinderRecommendationDescription", // done
    suggestionsImage: "perfumeFinderRecommendationImage", //done 
    suggestionsTitle: "perfumeFinderRecommendationTitle", //done 
    suggestionsButton: "discoverButton",//done 


// Request Samples

    discoverHeader: "perfumeFinderDiscoverHeader", //done 
    discoverImage: "perfumeFinderDiscoverImage", //done
    discoverButton: "perfumeFinderDiscoverButton" //done 



}