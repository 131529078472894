import React, { useContext } from "react";
import WizardLandingContent from "./WizardLandingContent";
import PropTypes from "prop-types";
import { AppContext } from "../../data/stores/app.store";

/**
 * A WizardLanding component displays a landing page related to a wizard flow. 
 *   - It takes in components. 
 *   - It displays a header relevant to the wizards purpose.
 *   - It renders a WizardLandingContent component and accepts its props.
 */

const WizardLanding = (props) => {
  const {appData} = useContext(AppContext);
    return (
      <div className="wizard-landing-wrapper"> 
        <h2 id={appData?.semanticList.mainHeader}>
          {props.header.lead} <span>{props.header.trail}</span>
        </h2>
        <WizardLandingContent components={props.components} {...props} />
      </div>
    );
}

WizardLanding.propTypes = {
  components: PropTypes.object,
  header: PropTypes.shape({
    lead: PropTypes.string,
    trail: PropTypes.string
  }),
}

export default WizardLanding;