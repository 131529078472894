import { locales } from "../configs/locales"

// Data Related to the Frederic Malle Brand as a whole

const euMarkets = [
  "si", "sk", "rs", "ro", "ch", "se", "es", "pt", "pl", "no", "nl", "mc", "mt", "lu", "lt", "lv", "it", "ie", "hu", "gr", "de", "fi", "ee", "dk", "cz", "hr", "bg", "be", "at"
]; 

export const BrandStore = {
  name: "Frederic Malle",
  prefix: "fm",
  markets: {
    fr: {
      defaultLanguage: 'fr',
      availableLanguages: ['en','fr'],
      REGION_CODE: 'UF',
      REGION_ID: 69,
      COUNTRY_ID: 28,
      COUNTRY: 'FR',
      url: "https://www.fredericmalle.eu/fr-e-UF/",
      regionSource: "https://edp.fredericmalle.eu"
    },
    uk: {
      defaultLanguage: 'en',
      availableLanguages: ['en'],
      REGION_CODE: 'UK',
      REGION_ID: 1,
      COUNTRY_ID: 2,
      COUNTRY: 'UK',
      url: "https://www.fredericmalle.co.uk/",
      regionSource: "http://edp.fredericmalle.co.uk"
    },
    us: {
      defaultLanguage: 'en',
      availableLanguages: ['en'],
      REGION_CODE: 'US',
      REGION_ID: 0,
      COUNTRY_ID: 1,
      COUNTRY: 'US',
      url: "https://www.fredericmalle.com/",
      regionSource: "https://edp.fredericmalle.com"
    },
    si: {
      defaultLanguage: 'en',
      availableLanguages: ['en','fr'],
      REGION_CODE: 'SI',
      REGION_ID: 87,
      COUNTRY_ID: 87,
      COUNTRY: 'SI',
      url: "https://www.fredericmalle.eu/",
      regionSource: "https://edp.fredericmalle.eu"
    },
    sk: {
      defaultLanguage: 'en',
      availableLanguages: ['en','fr'],
      REGION_CODE: 'SK',
      REGION_ID: 86,
      COUNTRY_ID: 86,
      COUNTRY: 'SK',
      region: 'EMEA',
      url: "https://www.fredericmalle.eu/",
      regionSource: "https://edp.fredericmalle.eu"
    },
    rs: {
      defaultLanguage: 'en',
      availableLanguages: ['en','fr'],
      REGION_CODE: 'RS',
      REGION_ID: 84,
      COUNTRY_ID: 84,
      COUNTRY: 'RS',
      region: 'EMEA',
      url: "https://www.fredericmalle.eu/",
      regionSource: "https://edp.fredericmalle.eu"
    },
    ro: {
      defaultLanguage: 'en',
      availableLanguages: ['en','fr'],
      REGION_CODE: 'RO',
      REGION_ID: 57,
      COUNTRY_ID: 80,
      COUNTRY: 'RO',
      region: 'EMEA',
      url: "https://www.fredericmalle.eu/",
      regionSource: "https://edp.fredericmalle.eu"
    },
    ch: {
      defaultLanguage: 'en',
      availableLanguages: ['en','fr'],
      REGION_CODE: 'CH',
      REGION_ID: 29,
      COUNTRY_ID: 201,
      COUNTRY: 'CH',
      region: 'EMEA',
      url: "https://www.fredericmalle.eu/",
      regionSource: "https://edp.fredericmalle.eu"
    },
    se: {
      defaultLanguage: 'en',
      availableLanguages: ['en','fr'],
      REGION_CODE: 'SE',
      REGION_ID: 23,
      COUNTRY_ID: 91,
      COUNTRY: 'SE',
      region: 'EMEA',
      url: "https://www.fredericmalle.eu/",
      regionSource: "https://edp.fredericmalle.eu"
    },
    es: {
      defaultLanguage: 'en',
      availableLanguages: ['en','fr'],
      REGION_CODE: 'ES',
      REGION_ID: 4,
      COUNTRY_ID: 89,
      COUNTRY: 'ES',
      region: 'EMEA',
      url: "https://www.fredericmalle.eu/",
      regionSource: "https://edp.fredericmalle.eu"
    },
    pt: {
      defaultLanguage: 'en',
      availableLanguages: ['en','fr'],
      REGION_CODE: 'PT',
      REGION_ID: 14,
      COUNTRY_ID: 79,
      COUNTRY: 'PT',
      region: 'EMEA',
      url: "https://www.fredericmalle.eu/",
      regionSource: "https://edp.fredericmalle.eu"
    },
    pl: {
      defaultLanguage: 'en',
      availableLanguages: ['en','fr'],
      REGION_CODE: 'PL',
      REGION_ID: 20,
      COUNTRY_ID: 78,
      COUNTRY: 'PL',
      region: 'EMEA',
      url: "https://www.fredericmalle.eu/",
      regionSource: "https://edp.fredericmalle.eu"
    },
    no: {
      defaultLanguage: 'en',
      availableLanguages: ['en','fr'],
      REGION_CODE: 'NO',
      REGION_ID: 24,
      COUNTRY_ID: 70,
      COUNTRY: 'NO',
      region: 'EMEA',
      url: "https://www.fredericmalle.eu/",
      regionSource: "https://edp.fredericmalle.eu"
    },
    nl: {
      defaultLanguage: 'en',
      availableLanguages: ['en','fr'],
      REGION_CODE: 'NL',
      REGION_ID: 17,
      COUNTRY_ID: 66,
      COUNTRY: 'NL',
      region: 'EMEA',
      url: "https://www.fredericmalle.eu/",
      regionSource: "https://edp.fredericmalle.eu"
    },
    mc: {
      defaultLanguage: 'en',
      availableLanguages: ['en','fr'],
      REGION_CODE: 'MC',
      REGION_ID: 0,
      COUNTRY_ID: 1,
      COUNTRY: 'MC',
      region: 'EMEA',
      url: "https://www.fredericmalle.eu/",
      regionSource: "https://edp.fredericmalle.eu"
    },
    mt: {
      defaultLanguage: 'en',
      availableLanguages: ['en','fr'],
      REGION_CODE: 'MT',
      REGION_ID: 0,
      COUNTRY_ID: 1,
      COUNTRY: 'MT',
      region: 'EMEA',
      url: "https://www.fredericmalle.eu/",
      regionSource: "https://edp.fredericmalle.eu"
    },
    lu: {
      defaultLanguage: 'en',
      availableLanguages: ['en','fr'],
      REGION_CODE: 'LU',
      REGION_ID: 0,
      COUNTRY_ID: 1,
      COUNTRY: 'LU',
      region: 'EMEA',
      url: "https://www.fredericmalle.eu/",
      regionSource: "https://edp.fredericmalle.eu"
    },
    lt: {
      defaultLanguage: 'en',
      availableLanguages: ['en','fr'],
      REGION_CODE: 'LT',
      REGION_ID: 0,
      COUNTRY_ID: 1,
      COUNTRY: 'LT',
      region: 'EMEA',
      url: "https://www.fredericmalle.eu/",
      regionSource: "https://edp.fredericmalle.eu"
    },
    lv: {
      defaultLanguage: 'en',
      availableLanguages: ['en','fr'],
      REGION_CODE: 'LV',
      REGION_ID: 0,
      COUNTRY_ID: 1,
      COUNTRY: 'LV',
      region: 'EMEA',
      url: "https://www.fredericmalle.eu/",
      regionSource: "https://edp.fredericmalle.eu"
    },
    it: {
      defaultLanguage: 'en',
      availableLanguages: ['en','fr'],
      REGION_CODE: 'IT',
      REGION_ID: 0,
      COUNTRY_ID: 1,
      COUNTRY: 'IT',
      region: 'EMEA',
      url: "https://www.fredericmalle.eu/",
      regionSource: "https://edp.fredericmalle.eu"
    },
    ie: {
      defaultLanguage: 'en',
      availableLanguages: ['en','fr'],
      REGION_CODE: 'IE',
      REGION_ID: 0,
      COUNTRY_ID: 1,
      COUNTRY: 'IE',
      region: 'EMEA',
      url: "https://www.fredericmalle.eu/",
      regionSource: "https://edp.fredericmalle.eu"
    },
    hu: {
      defaultLanguage: 'en',
      availableLanguages: ['en','fr'],
      REGION_CODE: 'HU',
      REGION_ID: 0,
      COUNTRY_ID: 1,
      COUNTRY: 'HU',
      region: 'EMEA',
      url: "https://www.fredericmalle.eu/",
      regionSource: "https://edp.fredericmalle.eu"
    },
    gr: {
      defaultLanguage: 'en',
      availableLanguages: ['en','fr'],
      REGION_CODE: 'GR',
      REGION_ID: 0,
      COUNTRY_ID: 1,
      COUNTRY: 'GR',
      region: 'EMEA',
      url: "https://www.fredericmalle.eu/",
      regionSource: "https://edp.fredericmalle.eu"
    },
    de: {
      defaultLanguage: 'en',
      availableLanguages: ['en','fr'],
      REGION_CODE: 'DE',
      REGION_ID: 0,
      COUNTRY_ID: 1,
      COUNTRY: 'DE',
      region: 'EMEA',
      url: "https://www.fredericmalle.eu/",
      regionSource: "https://edp.fredericmalle.eu"
    },
    fi: {
      defaultLanguage: 'en',
      availableLanguages: ['en','fr'],
      REGION_CODE: 'FI',
      REGION_ID: 0,
      COUNTRY_ID: 1,
      COUNTRY: 'FI',
      region: 'EMEA',
      url: "https://www.fredericmalle.eu/",
      regionSource: "https://edp.fredericmalle.eu"
    },
    ee: {
      defaultLanguage: 'en',
      availableLanguages: ['en','fr'],
      REGION_CODE: 'EE',
      REGION_ID: 0,
      COUNTRY_ID: 1,
      COUNTRY: 'EE',
      region: 'EMEA',
      url: "https://www.fredericmalle.eu/",
      regionSource: "https://edp.fredericmalle.eu"
    },
    dk: {
      defaultLanguage: 'en',
      availableLanguages: ['en','fr'],
      REGION_CODE: 'DK',
      REGION_ID: 0,
      COUNTRY_ID: 1,
      COUNTRY: 'DK',
      region: 'EMEA',
      url: "https://www.fredericmalle.eu/",
      regionSource: "https://edp.fredericmalle.eu"
    },
    cz: {
      defaultLanguage: 'en',
      availableLanguages: ['en','fr'],
      REGION_CODE: 'CZ',
      REGION_ID: 0,
      COUNTRY_ID: 1,
      COUNTRY: 'CZ',
      region: 'EMEA',
      url: "https://www.fredericmalle.eu/",
      regionSource: "https://edp.fredericmalle.eu"
    },
    hr: {
      defaultLanguage: 'en',
      availableLanguages: ['en','fr'],
      REGION_CODE: 'HR',
      REGION_ID: 0,
      COUNTRY_ID: 1,
      COUNTRY: 'HR',
      region: 'EMEA',
      url: "https://www.fredericmalle.eu/",
      regionSource: "https://edp.fredericmalle.eu"
    },
    bg: {
      defaultLanguage: 'en',
      availableLanguages: ['en','fr'],
      REGION_CODE: 'BG',
      REGION_ID: 0,
      COUNTRY_ID: 1,
      COUNTRY: 'BG',
      region: 'EMEA',
      url: "https://www.fredericmalle.eu/",
      regionSource: "https://edp.fredericmalle.eu"
    },
    be: {
      defaultLanguage: 'en',
      availableLanguages: ['en','fr'],
      REGION_CODE: 'BE',
      REGION_ID: 0,
      COUNTRY_ID: 1,
      COUNTRY: 'BE',
      region: 'EMEA',
      url: "https://www.fredericmalle.eu/",
      regionSource: "https://edp.fredericmalle.eu"
    },
    at: {
      defaultLanguage: 'en',
      availableLanguages: ['en','fr'],
      REGION_CODE: 'AT',
      REGION_ID: 0,
      COUNTRY_ID: 1,
      COUNTRY: 'AT',
      region: 'EMEA',
      url: "https://www.fredericmalle.eu/",
      regionSource: "https://edp.fredericmalle.eu"
    },
    en: {
      defaultLanguage: 'en',
      availableLanguages: ['en'],
      REGION_CODE: 'US',
      REGION_ID: 0,
      COUNTRY_ID: 1,
      COUNTRY: 'US',
      url: "https://www.fredericmalle.com/",
      regionSource: "https://edp.fredericmalle.eu"
    },
    gb: {
      defaultLanguage: 'en',
      availableLanguages: ['en','fr'],
      REGION_CODE: 'GB',
      REGION_ID: 1,
      COUNTRY_ID: 2,
      COUNTRY: 'GB',
      region: 'EMEA',
      url: "https://www.fredericmalle.co.uk/",
      regionSource: "https://edp.fredericmalle.co.uk"
    },
    eu: {
      defaultLanguage: 'en',
      availableLanguages: ['en','fr'],
      REGION_CODE: 'EU',
      REGION_ID: 1,
      COUNTRY_ID: 2,
      COUNTRY: 'EU',
      region: 'EMEA',
      url: "https://www.fredericmalle.eu",
      regionSource: "https://edp.fredericmalle.eu"
    }
  },
  locales: locales,
  euMarkets: euMarkets
}
