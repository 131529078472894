import React from "react";

const InfoLayout = (props) => {  
  return (
    <div className={"site-wrapper elc-wrapper fm-app fm-wrapper" + " " + props.backgroundColor + " " + (props.theme?props.theme:"")}>
      <header className={"header " + props.headerClasses}>
        {props.header}
      </header>
      <main className="content">
        {props.children}
      </main>
      <footer className={"footer " + props.footerClasses}>
        {props.footer}
      </footer>
    </div>
  )
}

export default InfoLayout;