import React, { useEffect, useState, useContext } from "react";
import { ToggleButton } from "react-bootstrap";
import { useFormikContext } from "formik";
import { PFContext } from "../../data/stores/app.store";
import PropTypes from "prop-types";
import ResponsiveGridImage from "../UI/ResponsiveGridImage";
import _ from "lodash";
import { setSelectionState, selectionsAllState } from "../../transformers/fmTransformers";

/**
 * A PFGridSelectItem component renders the grid of images to select. 
 *   - It updates selections.
 *   - It displays the image grid.
 */

const PFGridSelectItem = (props) => {
    const [checked, setChecked] = useState(false);
    const [selection, setSelection] = useState({});
    const [currentValues, setCurrentValues] = useState([]);

    // states for keeping track of select
    const { setFieldValue, handleChange, values } = useFormikContext()  ?? {};

    const { setWizardSelections } = useContext(PFContext);

    let itemCheckState = Object.keys(props?.selections).length > 0 && props?.selections[props.selectionData.name]?.checked;

    useEffect(() => {
      // componentDidMount - On initial load
      // get and set your wizrd props here
      setChecked(itemCheckState);
      const thisSelection = {...props.selectionData};
      //props for selection count
      setSelection(thisSelection);
      if (typeof setWizardSelections === "function") {
        values ? setWizardSelections(values):setWizardSelections({});
        values ? setCurrentValues(values[props.name]):setCurrentValues({});
      }
      return () => {
        // ** ALWAYS cleanup
      };
    },[values, itemCheckState]); // Dependency array, listen for changes to wizardProps

    const setNewSelection = (e, theseValues) => {
      theseValues.push(selection);
      const newValues = theseValues.filter(item => item.name === props.selectionData.name);
      props.setSelectionCount(1);
      setFieldValue(props.name,newValues);
      const thisSelect = setSelectionState(props.selectionData.name,props.selections,e.currentTarget.checked)
      props.setSelections(thisSelect);
    }

    const clearAllSelections = (e,theseValues) => {
      const clearAll = selectionsAllState(props.selections,false);
      props.setSelections(clearAll);
      const newValues = [];
      setFieldValue(props.name,newValues)
    }

    const clearAndSetSingle = (e, theseValues) => {
      clearAllSelections(e,theseValues);
      return setNewSelection(e,theseValues);
    }

    return (
      <li>
        <ToggleButton 
          checked={checked}
          onChange={(e) => {
            if(values){
              typeof handleChange === "function" && handleChange(e);
              const theseValues = values[props.name];
              if(e.currentTarget.checked){
                if(props.selectCount < props.step?.max_answers){
                  theseValues.push(selection);
                  props.setSelectionCount(props.selectCount + 1);
                  const thisSelect = setSelectionState(props.selectionData.name,props.selections,e.currentTarget.checked)
                  props.setSelections(thisSelect);
                  setFieldValue(props.name,theseValues)
                }else if (props.selectCount === 1 && props.step?.max_answers === 1) {
                  clearAndSetSingle(e, theseValues);
                }else{
                  e.currentTarget.checked = false;
                  const thisSelect = setSelectionState(props.selectionData.name,props.selections,e.currentTarget.checked)
                  props.setSelections(thisSelect);
                  const adjustVal = values[props.name];
                  setFieldValue(props.name,adjustVal);
                }
              }else{
                const newValues = theseValues.filter(item => item.name !== props.selectionData.name);
                setFieldValue(props.name,newValues)
                props.setSelectionCount(props.selectCount >= 1 && props.selectCount - 1);
                e.currentTarget.checked = false;
                const thisSelect = setSelectionState(props.selectionData.name,props.selections,e.currentTarget.checked)
                props.setSelections(thisSelect);
              }
            }
          }} 
          id={"pfSelectOption" + props?.selectionData?.name} 
          type={props.type} 
          name={props.name} 
          value={props?.selectionData?.name} 
          className="selection-item"
          data-testid={`toggle-button-${props?.selectionData?.name}`}
        >
          <ResponsiveGridImage
            selectionData={props?.selectionData}
            breakpoints={props?.breakpoints}
            currentValues={currentValues}
            id={"pfSelectOptionImage" + props?.selectionData?.name} 
          ></ResponsiveGridImage>
        </ToggleButton>
      </li>
    );
};

PFGridSelectItem.propTypes = {
  selectionData: PropTypes.object,
  name: PropTypes.string,
  type: PropTypes.string,
  breakpoints: PropTypes.object,
}

export default PFGridSelectItem;