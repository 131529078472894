export const languages = [
  {
    id: "en",
    name: "English",
  },
  {
    id: "fr",
    name: "Français",
  }
];

export const getLanguageFromLocale = () => {
  const browserLanguage = navigator.language;
  const [language, countrish] = browserLanguage.includes('-') 
    ? browserLanguage.split('-').map(lang => lang.toLowerCase()) 
    : [browserLanguage.toLowerCase(), browserLanguage.toLowerCase()];
  
  const languageExists =  languages.find(lang => lang.id === language);
  return languageExists ? language:false;
}