import React from "react";
import PropTypes from "prop-types";

/**
 * A Logo component renders the logo. 
 */

export default function Logo(props) {
    return (
      <h1 className={props.appPrefix + "-header"} id={props.id} aria-label="Brand-Header">
        {props.type === "vector" && props.component && <props.component />}
        <span className="visually-hidden">{props.altText}</span>
      </h1>
    );
}

Logo.propTypes = {
  appPrefix: PropTypes.string,
  type: PropTypes.string,
  component: PropTypes.func,
  altText: PropTypes.string,
}