import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { selectionState } from "../../transformers/fmTransformers";
import _ from "lodash";

/**
 * A WizardContent component *does something*. 
 */

const WizardContent = (props) => {
    const checkForState = props.step && props.step[props.context.list] && props.step[props.context.list].length > 0;
    const Item = props.item;
    const [selectCount, setSelectionCount] = useState(0);
    const [selections, setSelections] = useState([]);

    useEffect(() => {
      if(selections.length <= 0){
        
        const initialSelectState = selectionState(_.cloneDeep(props.content));
        setSelections(initialSelectState);
      }
      return () => {
        // ** ALWAYS cleanup
      };
    },[selections]); // Dependency array, listen for changes to wizardProps

    return (checkForState && 
      props.content?.map((item, i) => {
        return (
          <Item 
            key={props.step && props.step[props.context.select] + i} index={i} 
            {...props} 
            selectionData={item} 
            name={props.step && props.step[props.context.select]} 
            type={props.context.selectType}
            selectCount={selectCount}
            setSelectionCount={setSelectionCount}
            selections={selections}
            setSelections={setSelections}
          />
        );
      })
    );
}

WizardContent.propTypes = {
  step: PropTypes.object,
  context: PropTypes.object,
  item: PropTypes.any,
  content: PropTypes.array,
  breakpoints: PropTypes.object,
}

export default WizardContent;