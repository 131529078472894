import React, {useContext}from "react";
import { Row } from "react-bootstrap";
import PropTypes from "prop-types";
import PFSlogan from "../Brand/PFSlogan";
import InfoLayout from "../Layouts/InfoLayout";
import PFHeader from "../Brand/PFHeader";
import { AppContext } from "../../data/stores/app.store";
// import { useContext } from "react";

/**
 * A PageNotFound component renders when they run across a 404 error
 */


const PageNotFound = (props) => {
    const handleStartOver = () => {

        window.location.href = "/";
    
      };
      const {appData} = useContext(AppContext);

    return (
        <InfoLayout 
            header={<PFHeader/>}
            headerClasses="navbar-header"
            footerClasses="navbar fm-footer"
            backgroundColor={"#000"}
        >
            <div className="no-selections-page px-0 mx-0 site-wrapper elc-wrapper fm-app fm-wrapper text-center align-items-center">
                <Row className="w-100 mx-0">
                    <h2 className="error-title">{props.data.title}</h2>
                </Row>
                <Row className="w-100 mx-0">
                    <h5 className="return-home">
                        <button className="quiz-start-over-button" id={appData?.semanticList?.restartButton} onClick={handleStartOver}> {props.data.button}
                        </button>
                    </h5>
                </Row>
                <Row className="bottom-sticky-slogan w-100 mx-0">
                    <PFSlogan data={props.data} />
                </Row>
            </div> 
        </InfoLayout>
    );

}

PageNotFound.propTypes = {
    data: PropTypes.object,
}


export default PageNotFound;