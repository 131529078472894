import React from "react";
import { FredericMalleLogo } from "../../assets/vector/vector";
import { languages } from "../../utils/languages";
import { quizSelectionsPerStep, quizSelections } from "../../transformers/fmTransformers";
import GenderSelector from "../..//components/Brand/GenderSelector";
import PFDisclaimerAgree from "../../components/Brand/PFDisclaimerAgree";
import ScoredWizard from "../../components/UI/ScoredWizard";
import PFSplash from "../../components/Brand/PFSplash";
import PFSelections from "../../components/Brand/PFSelections";
import PFResults from "../../components/Brand/PFResults";
import PFResultsDisplay from "../../components/Brand/PFResultsDisplay";
import PFPerfumeReccs from "../../components/Brand/PFPerfumeReccs";
import PFGridSelectItem from "../../components/Brand/PFGridSelectItem";
import PFSelectControls from "../../components/Brand/PFSelectControls";
import { ButtonGroup } from "react-bootstrap";
import Icon from "../../components/UI/Icon";
import { getProductScore, updateBack, updateFirst } from "../../utils/scoringUtils";
import PFEmailSlideout from "../../components/Brand/PFEmailSlideout";
import PFPromos from "../../components/Brand/PFPromos";
import samplesCover from '../../assets/images/samples-cover.webp';
import storeFinderCover from '../../assets/images/store-finder-cover.webp';
import { trackGenderSelect } from "../../analytics/PerfumeFinderConfig_ga";
import { idConfig } from "./idConfig";
import fm_data_controllers from "../../assets/pdf/fm_data_controllers.pdf";

export const PerfumeFinderConfig = {
  'en': {
    appName: "Frederick Malle Perfume Finder",
    appLogo: FredericMalleLogo,
    urls: {
      backend: {
        dev: "http://localhost:5002",
        prod: "",
        //prod: "https://rep-frederic-malle-cms.elcompanies.com/api/v2",
      },
      frontend: "",
    },
    breakpoints: {
      sm: {
        imageSelector: "small",
        classNames: "d-block d-sm-none",
        maxWidth: 767,
        imageSize: 108,
      },
      md: {
        imageSelector: "medium",
        classNames: "d-none d-sm-block d-md-none",
        maxWidth: 1439,
        imageSize: 180,
      },
      lg: {
        imageSelector: "large",
        classNames: "d-none d-md-block d-lg-none",
        imageSize: 204,
      },
      xl: {
        imageSelector: "xl",
        classNames: "d-none d-lg-block d-xl-none",
      },
      original: {
        imageSelector: "original",
        classNames: "d-none d-xl-block",
      },
    },
    perfumeFinderFields: {
      looks: [],
      hair: [],
      footwear: [],
      transport: [],
      underwear: [],
      accessories: [],
      environment: [],
    },
    productScores: {
      686: 0,
      694: 0,
      695: 0,
      696: 0,
      697: 0,
      698: 0,
      705: 0,
      710: 0,
      714: 0,
      716: 0,
      718: 0,
      720: 0,
      722: 0,
      724: 0,
      726: 0,
      729: 0,
      731: 0,
      733: 0,
      735: 0,
      737: 0,
      740: 0,
      742: 0,
      743: 0,
      746: 0,
      766: 0,
      767: 0,
      774: 0,
      788: 0,
      793: 0,
      794: 0,
      814: 0,
      817: 0
    },
    pageNotFound: {
      title: "404 - Page Not Found",
      button: "Back to Homepage",
      slogan: "L'avant-garde du parfum",
      sloganSubText: "Olfactive masterpieces signed by the world’s greatest perfumers",
    },
    semanticList: idConfig,
    endpoints: {
      languages: () => ("/nodeAPI/languages"),
      avialbaleQuizes: () => ("/nodeAPI/quiz"),
      thisQuiz: (id) => (`/nodeAPI/quiz/${id}`),
      markets: () => ("/nodeAPI/markets"),
      marketConfig: (market_code) => (`/nodeAPI/markets/${market_code}/config`),
      marketProducts: (mid) => (`/nodeAPI/markets/${mid}/products`),
      marketRetailers: (mid) => (`/nodeAPI/markets/${mid}/retailers`),
      productCats: () => ("/nodeAPI/product-categories"),
      regions: () => ("/nodeAPI/regions"),
      stores: () => ("/nodeAPI/stores"),
      storesPerRetailer: (rid) => (`/nodeAPI/retailers/${rid}/stores`),
      env: () => (`/nodeAPI/env`),
      submitEmail: () => ("/nodeAPI/email/perfume-finder-results"),
    },
    languages: languages,
  },
  'fr': {
    appName: "Frederick Malle Perfume Finder",
    productsURL: "https://www.fredericmalle.eu/fr-e-uf/product/19566/",
    appLogo: FredericMalleLogo,
    urls: {
      backend: {
        dev: "http://localhost:5002",
        prod: "",
        //prod: "https://rep-frederic-malle-cms.elcompanies.com/api/v2",
      },
      frontend: "",
    },
    breakpoints: {
      sm: {
        imageSelector: "small",
        classNames: "d-block d-sm-none",
        maxWidth: 767,
        imageSize: 108,
      },
      md: {
        imageSelector: "medium",
        classNames: "d-none d-sm-block d-md-none",
        maxWidth: 1439,
        imageSize: 180,
      },
      lg: {
        imageSelector: "large",
        classNames: "d-none d-md-block d-lg-none",
        imageSize: 204,
      },
      xl: {
        imageSelector: "xl",
        classNames: "d-none d-lg-block d-xl-none",
      },
      original: {
        imageSelector: "original",
        classNames: "d-none d-xl-block",
      },
    },
    perfumeFinderFields: {
      looks: [],
      hair: [],
      footwear: [],
      transport: [],
      underwear: [],
      accessories: [],
      environment: [],
    },
    productScores: {
      686: 0,
      694: 0,
      695: 0,
      696: 0,
      697: 0,
      698: 0,
      705: 0,
      710: 0,
      714: 0,
      716: 0,
      718: 0,
      720: 0,
      722: 0,
      724: 0,
      726: 0,
      729: 0,
      731: 0,
      733: 0,
      735: 0,
      737: 0,
      740: 0,
      742: 0,
      743: 0,
      746: 0,
      766: 0,
      767: 0,
      774: 0,
      788: 0,
      793: 0,
      794: 0,
      814: 0,
      817: 0
    },
    pageNotFound: {
      title: "404 - Page Non Trouvée",
      button: "Retour a la page d'accueil",
      slogan: "L'avant-garde du parfum",
      sloganSubText: "Des chefs d’œuvres olfactifs signés par les plus grands parfumeurs",
    },
    semanticList: idConfig,
    endpoints: {
      languages: () => ("/nodeAPI/languages"),
      avialbaleQuizes: () => ("/nodeAPI/quiz"),
      thisQuiz: (id) => (`/nodeAPI/quiz/${id}`),
      markets: () => ("/nodeAPI/markets"),
      marketConfig: (market_code) => (`/nodeAPI/markets/${market_code}/config`),
      marketProducts: (mid) => (`/nodeAPI/markets/${mid}/products`),
      marketRetailers: (mid) => (`/nodeAPI/markets/${mid}/retailers`),
      productCats: () => ("/nodeAPI/product-categories"),
      regions: () => ("/nodeAPI/regions"),
      stores: () => ("/nodeAPI/stores"),
      storesPerRetailer: (rid) => (`/nodeAPI/retailers/${rid}/stores`),
      env: () => (`/nodeAPI/env`),
      submitEmail: () => ("/nodeAPI/email/perfume-finder-results"),
    },
    languages: languages,
  }
}

export const PerfumeFinderConfigFR = {
  'en': {
  ...PerfumeFinderConfig['en'],
  productsURL: "https://www.fredericmalle.eu/fr-e-uf/product/19566/",
  flow: {
    components: {
      intro: {
        component: PFSplash,
        props: {
          nextFlow: "wizard",
          handleGenderSelect: (e, props, state) => {
            props.setGender(e.target.value);
            state.setFlow(props.nextFlow);

            trackGenderSelect(e);
          },
          handlePrivacyAgree: (e, props, state) => {
            if (!props.privacyAgree) {
              if (props.gender === 'man' || props.gender === 'woman' || props.gender === 'na') {
                state.setFlow(props.nextFlow);
              }
            }
          },
          handleMarket: (e, props, state) => {
            if (props.markets === 'US') {
              state.setFlow(props.nextFlow);
            }
          },
        },
        subComponents: {
          selection: GenderSelector,
          disclaimer: PFDisclaimerAgree,
        }
      },
      wizard: {
        component: PFSelections,
        props: {
          nextFlow: "results",
          getStepData: (data, step, gender) => {
            return quizSelectionsPerStep(data, step, gender);
          },
          getPFData: (data, gender) => {
            return quizSelections(data, gender);
          },
          nextStep: (e, props, states, formikProps, noScrollTop) => {
            const handleNext = (props, states) => {
              props.setCurrentStep(props.currentStep + 1);
              if (states.wizardSelections) {
                getProductScore(props.stepContent.category, states.wizardSelections, states.productScores, states.setProductScores, states.setPreviousStep);
              }
            }
            //breaks when no network connection
            props.currentStep < props.steps?.length ?
              handleNext(props, states) :
              (states.wizardSelections && props.setFlow('results'));
            !noScrollTop && (document.getElementById("root")).scrollTo({ top: 0, behavior: 'smooth' });
          },
          previousStep: (e, props, states, formikProps, noScrollTop) => {
            const handleBack = (props, states, formikProps, currentStep) => {
              props.setCurrentStep(props.currentStep - 1);
              if (states.wizardSelections) {
                updateBack(states.wizardSelections, states.productScores, states.setProductScores, states.previousStep, states.setWizardSelections, formikProps, currentStep, states.setPreviousStep);
              }
            }
            const handleFirst = (props, states, formikProps, currentStep) => {
              if (states.wizardSelections) {
                props.setFlow('intro');
                updateFirst(states.wizardSelections, states.productScores, states.setProductScores, states.previousStep, states.setWizardSelections, formikProps, currentStep, states.setPreviousStep);
              }
            }
            props.currentStep > 1 ?
              handleBack(props, states, formikProps, props.currentStep) :
              handleFirst(props, states, formikProps, props.currentStep);
            !noScrollTop && (document.getElementById("root")).scrollTo({ top: 0, behavior: 'smooth' });
          },
          context: {
            select: 'category',
            list: 'answers',
            selectType: 'checkbox'
          }
        },
        subComponents: {
          wizard: ScoredWizard,
          selections: PFGridSelectItem,
          controls: PFSelectControls,
          group: ButtonGroup
        },
        controlsHeading: "Question",
        controlsDivider: "/",
        controlsSubHeaderCopy: " styles selected",
        controlsIcons: {
          prev: <Icon variant="arrow-left" />,
          next: <Icon variant="arrow-right" />,
        }
      },
      results: {
        component: PFResults,
        props: {
          nextFlow: "done",
        },
        subComponents: {
          hero: PFResultsDisplay,
          emails: PFEmailSlideout,
          reccomendations: PFPerfumeReccs,
          promos: PFPromos
        },
        sections: {
          hero: {
            title: "your selection",
            slogan: "L'avant-garde du parfum",
            sloganSubText: "Olfactive masterpieces signed by the world’s greatest perfumers",
            restartButtonText: "Start Over",
            component: PFResultsDisplay,
            selectionMap: {
              looks: {
                count: 2
              },
              hair: {
                count: 1
              },
              footwear: {
                count: 2
              },
              transport: {
                count: 1
              },
              underwear: {
                count: 1
              },
              accessories: {
                count: 3
              },
              environment: {
                count: 1
              },
            },
          },
          emailSlideout: {
            taglines: {
              emailCard: "Join our mailing list",
              popover: true,
              emailTagline:
                <>
                  Sign up to hear more from us and receive information on Éditions de Parfums Frederic Malle products, events and offers.
                </>,
              emailPlaceholder: "Enter your email",
              checkboxTagline: "By signing up you will receive email from Frédéric Malle about the latest products, special offers and expert tips.",
              confirmationTagline: "Thank you.",
              validationTagline: "Invalid email. Please try again.",
              tooltipTitle: "How do we use your data?",
              dataTooltip: 
                <div>
                  We will process your personal data in accordance with our <a href="https://www.fredericmalle.eu/customer-service-privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.  
                  For a full list of data controllers by country please <a href={fm_data_controllers} target="_blank" rel="noopener noreferrer">click here</a>. By submitting your email 
                  address you are requesting to receive news and information by email about Éditions de Parfums Frederic Malle products, events and offers. 
                  This will include product recommendations based on the results of the perfume match quiz.  
                  You can opt-out at any time by clicking the unsubscribe link in each email you receive.  For more information on our privacy practices and your 
                  rights please see our <a href="https://www.fredericmalle.eu/customer-service-privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>. 
                </div>
            },
          },
          reccomendations: {
            title: "Our suggestions for you",
            buttonText: "Discover"
          },
          promos: [
            {
              header: "Request your Samples",
              id: "RequestYourSamples",
              classes: "samples-request",
              image: samplesCover,
              link: "https://www.fredericmalle.eu/fr-e-uf/product/21165/55985/coffrets/trio-decouverte/sample-set-french#/sku/89913",
              actionText: "Order Sample",
              alt: "RequestYourSamples"
            },
            {
              header: "Discover in Stores",
              id: "DiscoverInStores",
              classes: "store-finder",
              image: storeFinderCover,
              link: "https://www.fredericmalle.eu/fr-e-uf/nos-boutiques",
              actionText: "Find a Store",
              alt: "DiscoverInStores"
            }
          ],
          noResults: {
            title: "No result.",
            subTitle: "Select illustrations to find the perfume that matches your personality.",
            button: "Take the Quiz",
            slogan: "L'avant-garde du parfum",
            sloganSubText: "Olfactive masterpieces signed by the world’s greatest perfumers",
            imageSet: [
              {
                name: "no-results-1",
                sm: "/assets/images/no-results-sm/sm_no_results_1.webp",
                md: "/assets/images/no-results-md/md_no_results_1.webp",
                lg: "/assets/images/no-results-lg/lg_no_results_1.webp",
              },
              {
                name: "no-results-2",
                sm: "/assets/images/no-results-sm/sm_no_results_2.webp",
                md: "/assets/images/no-results-md/md_no_results_2.webp",
                lg: "/assets/images/no-results-lg/lg_no_results_2.webp",
              },
              {
                name: "no-results-3",
                sm: "/assets/images/no-results-sm/sm_no_results_3.webp",
                md: "/assets/images/no-results-md/md_no_results_3.webp",
                lg: "/assets/images/no-results-lg/lg_no_results_3.webp",
              },
              {
                name: "no-results-4",
                sm: "/assets/images/no-results-sm/sm_no_results_4.webp",
                md: "/assets/images/no-results-md/md_no_results_4.webp",
                lg: "/assets/images/no-results-lg/lg_no_results_4.webp",
              },
              {
                name: "no-results-5",
                sm: "/assets/images/no-results-sm/sm_no_results_5.webp",
                md: "/assets/images/no-results-md/md_no_results_5.webp",
                lg: "/assets/images/no-results-lg/lg_no_results_5.webp",
              },
              {
                name: "no-results-6",
                sm: "/assets/images/no-results-sm/sm_no_results_6.webp",
                md: "/assets/images/no-results-md/md_no_results_6.webp",
                lg: "/assets/images/no-results-lg/lg_no_results_6.webp",
              },
              {
                name: "no-results-7",
                sm: "/assets/images/no-results-sm/sm_no_results_7.webp",
                md: "/assets/images/no-results-md/md_no_results_7.webp",
                lg: "/assets/images/no-results-lg/lg_no_results_7.webp",
              }
            ],
          },
        },
      }
    },
  },
  footer: {
    links:[
      {
        title: "Privacy Policy",
        url: "https://www.fredericmalle.eu/fr-e-UF/customer-service-privacy",
        target: "_blank"
      },
      {
        title: "Terms and Conditions",
        url: "https://www.fredericmalle.eu/customer-service-terms-and-conditions",
        target: "_blank"
      },
      {
        title: "Manage Cookies",
        url: "#",
        target: "_self",
        class: "optanon-show-settings",
      },
    ]
  },
  privacyDisclaimer: (
    <>
      By clicking the buttons below and making your selections in the next pages, 
      you agree to our processing of your personal data, including your responses to the 
      questions in the quiz, which may be characterized as sensitive personal data or special 
      categories of data depending on applicable law, for the purposes of providing personalized 
      product recommendations. For more information about Éditions de Parfums Frederic Malle, 
      please view our <a href="https://www.fredericmalle.eu/customer-service-privacy" className="privacy-policy-link" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
    </>
  ),  },
  'fr': {
  ...PerfumeFinderConfig['fr'],
  productsURL: "https://www.fredericmalle.eu/fr-e-uf/product/19566/",
  flow: {
    components: {
      intro: {
        component: PFSplash,
        props: {
          nextFlow: "wizard",
          handleGenderSelect: (e, props, state) => {
            props.setGender(e.target.value);
            state.setFlow(props.nextFlow);

            trackGenderSelect(e);
          },
          handlePrivacyAgree: (e, props, state) => {
            if (!props.privacyAgree) {
              if (props.gender === 'man' || props.gender === 'woman' || props.gender === 'na') {
                state.setFlow(props.nextFlow);
              }
            }
          },
          handleMarket: (e, props, state) => {
            if (props.markets === 'US') {
              state.setFlow(props.nextFlow);
            }
          },
        },
        subComponents: {
          selection: GenderSelector,
          disclaimer: PFDisclaimerAgree,
        }
      },
      wizard: {
        component: PFSelections,
        props: {
          nextFlow: "results",
          getStepData: (data, step, gender) => {
            return quizSelectionsPerStep(data, step, gender);
          },
          getPFData: (data, gender) => {
            return quizSelections(data, gender);
          },
          nextStep: (e, props, states, formikProps, noScrollTop) => {
            const handleNext = (props, states) => {
              props.setCurrentStep(props.currentStep + 1);
              if (states.wizardSelections) {
                getProductScore(props.stepContent.category, states.wizardSelections, states.productScores, states.setProductScores, states.setPreviousStep);
              }
            }
            //breaks when no network connection
            props.currentStep < props.steps?.length ?
              handleNext(props, states) :
              (states.wizardSelections && props.setFlow('results'));
            !noScrollTop && (document.getElementById("root")).scrollTo({ top: 0, behavior: 'smooth' });
          },
          previousStep: (e, props, states, formikProps, noScrollTop) => {
            const handleBack = (props, states, formikProps, currentStep) => {
              props.setCurrentStep(props.currentStep - 1);
              if (states.wizardSelections) {
                updateBack(states.wizardSelections, states.productScores, states.setProductScores, states.previousStep, states.setWizardSelections, formikProps, currentStep, states.setPreviousStep);
              }
            }
            const handleFirst = (props, states, formikProps, currentStep) => {
              if (states.wizardSelections) {
                props.setFlow('intro');
                updateFirst(states.wizardSelections, states.productScores, states.setProductScores, states.previousStep, states.setWizardSelections, formikProps, currentStep, states.setPreviousStep);
              }
            }
            props.currentStep > 1 ?
              handleBack(props, states, formikProps, props.currentStep) :
              handleFirst(props, states, formikProps, props.currentStep);
            !noScrollTop && (document.getElementById("root")).scrollTo({ top: 0, behavior: 'smooth' });
          },
          context: {
            select: 'category',
            list: 'answers',
            selectType: 'checkbox'
          }
        },
        subComponents: {
          wizard: ScoredWizard,
          selections: PFGridSelectItem,
          controls: PFSelectControls,
          group: ButtonGroup
        },
        controlsHeading: "Question",
        controlsDivider: "/",
        controlsSubHeaderCopy: " styles sélectionnés",
        controlsIcons: {
          prev: <Icon variant="arrow-left" />,
          next: <Icon variant="arrow-right" />,
        }
      },
      results: {
        component: PFResults,
        props: {
          nextFlow: "done",
        },
        subComponents: {
          hero: PFResultsDisplay,
          emails: PFEmailSlideout,
          reccomendations: PFPerfumeReccs,
          promos: PFPromos
        },
        sections: {
          hero: {
            title: "votre sélection",
            slogan: "L'avant-garde du parfum",
            sloganSubText: "Des chefs d’œuvres olfactifs signés par les plus grands parfumeurs",
            restartButtonText: "Recommencer",
            component: PFResultsDisplay,
            selectionMap: {
              looks: {
                count: 2
              },
              hair: {
                count: 1
              },
              footwear: {
                count: 2
              },
              transport: {
                count: 1
              },
              underwear: {
                count: 1
              },
              accessories: {
                count: 3
              },
              environment: {
                count: 1
              },
            },
          },
          emailSlideout: {
            taglines: {
              popover: true,
              emailCard: 
              <>
                Les actualités
                <br/>
                Éditions de Parfums
              </>,
              emailTagline: "Inscrivez-vous pour recevoir des informations sur les produits, les événements et les offres des Éditions de Parfums Frederic Malle",
              emailPlaceholder: "Entrez votre adresse email",
              checkboxTagline: "En vous inscrivant à la newsletter, vous recevrez les emails concernant les derniers produits, les offres et les conseils.",
              confirmationTagline: "Merci.",
              validationTagline: "Adresse email invalide. Essayez encore.",
              tooltipTitle: "Comment traitons-nous vos données personnelles?",
              dataTooltip: 
              <div>
                Les Éditions de Parfums Frederic Malle, une dénomination commerciale d'ELCO SAS, 40/48 Rue Cambon, Paris, France 75001, traiteront vos données personnelles conformément à notre <a href="https://www.fredericmalle.eu/fr-e-uf/customer-service-privacy" target="_blank" rel="noopener noreferrer">Politique de Confidentialité</a>.
                En fournissant votre adresse e-mail, vous accepter de recevoir par e-mail des actualités et des informations sur les produits, 
                les événements et les offres des Éditions de Parfums Frederic Malle. 
                Ces informations comprendront des recommandations de produits basées sur les résultats du quiz de correspondance des parfums.Vous pouvez vous désinscrire à tout moment en cliquant 
                sur le lien de désinscription dans chaque e-mail que vous recevrez. Pour plus d'informations sur nos pratiques en matière de protection de la vie privée et sur vos droits, 
                veuillez consulter notre <a href="https://www.fredericmalle.eu/fr-e-uf/customer-service-privacy" target="_blank" rel="noopener noreferrer">Politique de Confidentialité</a>.
              </div>
            },
          },
          reccomendations: {
            title: "Nos suggestions pour vous",
            buttonText: "Découvrir"
          },
          promos: [
            {
              id: "RequestYourSamples",
              header: "Demandez vos échantillons",
              classes: "samples-request",
              image: samplesCover,
              link: "https://www.fredericmalle.eu/fr-e-uf/product/21165/55985/boxes/sample-service/sample-set#/sku/89913",
              actionText: "Commander un échantillon",
              alt: "Demandez vos échantillons"
            },
            {
              id: "DiscoverInStores",
              header: "Découvrir en magasin",
              classes: "store-finder",
              image: storeFinderCover,
              link: "https://www.fredericmalle.eu/fr-e-uf/nos-boutiques",
              actionText: "Trouver un magasin",
              alt: "Découvrir en magasin"
            }
          ],
          noResults: {
            title: "Pas de résultat,",
            subTitle: "Sélectionnez des illustrations pour trouver le parfum qui vous correspond.",
            button: "Recommencer",
            slogan: "L'avant-garde du parfum",
            sloganSubText: "Des chefs d’œuvres olfactifs signés par les plus grands parfumeurs",

            imageSet: [
              {
                name: "no-results-1",
                sm: "/assets/images/no-results-sm/sm_no_results_1.webp",
                md: "/assets/images/no-results-md/md_no_results_1.webp",
                lg: "/assets/images/no-results-lg/lg_no_results_1.webp",
              },
              {
                name: "no-results-2",
                sm: "/assets/images/no-results-sm/sm_no_results_2.webp",
                md: "/assets/images/no-results-md/md_no_results_2.webp",
                lg: "/assets/images/no-results-lg/lg_no_results_2.webp",
              },
              {
                name: "no-results-3",
                sm: "/assets/images/no-results-sm/sm_no_results_3.webp",
                md: "/assets/images/no-results-md/md_no_results_3.webp",
                lg: "/assets/images/no-results-lg/lg_no_results_3.webp",
              },
              {
                name: "no-results-4",
                sm: "/assets/images/no-results-sm/sm_no_results_4.webp",
                md: "/assets/images/no-results-md/md_no_results_4.webp",
                lg: "/assets/images/no-results-lg/lg_no_results_4.webp",
              },
              {
                name: "no-results-5",
                sm: "/assets/images/no-results-sm/sm_no_results_5.webp",
                md: "/assets/images/no-results-md/md_no_results_5.webp",
                lg: "/assets/images/no-results-lg/lg_no_results_5.webp",
              },
              {
                name: "no-results-6",
                sm: "/assets/images/no-results-sm/sm_no_results_6.webp",
                md: "/assets/images/no-results-md/md_no_results_6.webp",
                lg: "/assets/images/no-results-lg/lg_no_results_6.webp",
              },
              {
                name: "no-results-7",
                sm: "/assets/images/no-results-sm/sm_no_results_7.webp",
                md: "/assets/images/no-results-md/md_no_results_7.webp",
                lg: "/assets/images/no-results-lg/lg_no_results_7.webp",
              }
            ],
          },
        },
      }
      },
    },
    footer: {
      links:[
        {
          title: "Politique de confidentialité",
          url: "https://www.fredericmalle.eu/fr-e-uf/customer-service-privacy",
          target: "_blank"
        },
        {
          title: "Conditions générales d'utilisation",
          url: "https://www.fredericmalle.eu/fr-e-uf/customer-service-terms-of-use",
          target: "_blank"
        },
        {
          title: "Gérer Les Cookies",
          url: "#",
          target: "_self",
          class: "optanon-show-settings",
        },
        {
          title: "Conditions générales de vente",
          url: "https://www.fredericmalle.eu/fr-e-UF/customer-service-terms-of-sale",
          target: "_blank"
        },
      ]
    },
    privacyDisclaimer: (
      <>
      En cliquant sur les boutons ci-dessous et en effectuant vos choix dans les pages suivantes, 
      vous consentez à ce que nous traitions vos données personnelles, y compris vos réponses aux 
      questions du quiz, qui peuvent être considérées comme des données personnelles sensibles ou des 
      catégories spéciales de données selon la législation en vigueur. Ceci dans le but de vous fournir des 
      recommandations de produits personnalisées. Pour plus d'informations sur Éditions de Parfums Frederic Malle, 
      veuillez consulter notre <a href="https://www.fredericmalle.eu/fr-e-uf/customer-service-privacy" className="privacy-policy-link" target="_blank" rel="noopener noreferrer">politique de confidentialité</a>.
      </>
    )
  }
};

export const PerfumeFinderConfigUS = {
  'en': {
    ...PerfumeFinderConfig['en'],
    productsURL: "https://www.fredericmalle.com/product/19566/",
    flow: {
      components: {
        intro: {
          component: PFSplash,
          props: {
            nextFlow: "wizard",
            handleGenderSelect: (e, props, state) => {
              props.setGender(e.target.value);
              state.setFlow(props.nextFlow);

              trackGenderSelect(e);
            },
            handlePrivacyAgree: (e, props, state) => {
              if (!props.privacyAgree) {
                if (props.gender === 'man' || props.gender === 'woman' || props.gender === 'na') {
                  state.setFlow(props.nextFlow);
                }
              }
            },
            handleMarket: (e, props, state) => {
              if (props.markets === 'US') {
                state.setFlow(props.nextFlow);
              }
            },
          },
          subComponents: {
            selection: GenderSelector,
            disclaimer: PFDisclaimerAgree,
          }
        },
        wizard: {
          component: PFSelections,
          props: {
            nextFlow: "results",
            getStepData: (data, step, gender) => {
              return quizSelectionsPerStep(data, step, gender);
            },
            getPFData: (data, gender) => {
              return quizSelections(data, gender);
            },
            nextStep: (e, props, states, formikProps, noScrollTop) => {
              const handleNext = (props, states) => {
                props.setCurrentStep(props.currentStep + 1);
                if (states.wizardSelections) {
                  getProductScore(props.stepContent.category, states.wizardSelections, states.productScores, states.setProductScores, states.setPreviousStep);
                }
              }
              //breaks when no network connection
              props.currentStep < props.steps?.length ?
                handleNext(props, states) :
                (states.wizardSelections && props.setFlow('results'));
              !noScrollTop && (document.getElementById("root")).scrollTo({ top: 0, behavior: 'smooth' });
            },
            previousStep: (e, props, states, formikProps, noScrollTop) => {
              const handleBack = (props, states, formikProps, currentStep) => {
                props.setCurrentStep(props.currentStep - 1);
                if (states.wizardSelections) {
                  updateBack(states.wizardSelections, states.productScores, states.setProductScores, states.previousStep, states.setWizardSelections, formikProps, currentStep, states.setPreviousStep);
                }
              }
              const handleFirst = (props, states, formikProps, currentStep) => {
                if (states.wizardSelections) {
                  props.setFlow('intro');
                  updateFirst(states.wizardSelections, states.productScores, states.setProductScores, states.previousStep, states.setWizardSelections, formikProps, currentStep, states.setPreviousStep);
                }
              }
              props.currentStep > 1 ?
                handleBack(props, states, formikProps, props.currentStep) :
                handleFirst(props, states, formikProps, props.currentStep);
              !noScrollTop && (document.getElementById("root")).scrollTo({ top: 0, behavior: 'smooth' });
            },
            context: {
              select: 'category',
              list: 'answers',
              selectType: 'checkbox'
            }
          },
          subComponents: {
            wizard: ScoredWizard,
            selections: PFGridSelectItem,
            controls: PFSelectControls,
            group: ButtonGroup
          },
          controlsHeading: "Question",
          controlsDivider: "/",
          controlsSubHeaderCopy: " styles selected",
          controlsIcons: {
            prev: <Icon variant="arrow-left" />,
            next: <Icon variant="arrow-right" />,
          }
        },
        results: {
          component: PFResults,
          props: {
            nextFlow: "done",
          },
          subComponents: {
            hero: PFResultsDisplay,
            emails: PFEmailSlideout,
            reccomendations: PFPerfumeReccs,
            promos: PFPromos
          },
          sections: {
            hero: {
              title: "your selection",
              slogan: "L'avant-garde du parfum",
              sloganSubText: "Olfactive masterpieces signed by the world’s greatest perfumers",
              restartButtonText: "Start Over",
              component: PFResultsDisplay,
              selectionMap: {
                looks: {
                  count: 2
                },
                hair: {
                  count: 1
                },
                footwear: {
                  count: 2
                },
                transport: {
                  count: 1
                },
                underwear: {
                  count: 1
                },
                accessories: {
                  count: 3
                },
                environment: {
                  count: 1
                },
              },
            },
            emailSlideout: {
              taglines: {
                checkbox: true,
                popover: false,
                emailCard: "Join our mailing list",
                emailTagline:
                  <div className="text-no-margin-padding">
                    Be the first to receive information on new launches, events, and exclusivities by subscribing to the newsletter Editions de Parfums Frederic Malle. For more information, view our <a href="https://www.fredericmalle.com/customer-service-privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
                  </div>,
                emailPlaceholder: "Enter your email",
                checkboxTagline: "By signing up you will receive email from Frédéric Malle about the latest products, special offers and expert tips.",
                confirmationTagline: "Thank you.",
                validationTagline: "Invalid email. Please try again.",
              },
            },
            reccomendations: {
              title: "Our suggestions for you",
              buttonText: "Discover"
            },
            promos: [
              {
                header: "Request your Samples",
                id: "RequestYourSamples",
                classes: "samples-request",
                image: samplesCover,
                link: "https://www.fredericmalle.com/product/21165/55985/boxes/sample-service/sample-set#/sku/89913",
                actionText: "Order Sample",
                alt: "RequestYourSamples"
              },
              {
                header: "Discover in Stores",
                id: "DiscoverInStores",
                classes: "store-finder",
                image: storeFinderCover,
                link: "https://www.fredericmalle.com/about/stores",
                actionText: "Find a Store",
                alt: "DiscoverInStores"
              }
            ],
            noResults: {
              title: "No result.",
              subTitle: "Select illustrations to find the perfume that matches your personality.",
              button: "Take the Quiz",
              slogan: "L'avant-garde du parfum",
              sloganSubText: "Olfactive masterpieces signed by the world’s greatest perfumers",
              imageSet: [
                {
                  name: "no-results-1",
                  sm: "/assets/images/no-results-sm/sm_no_results_1.webp",
                  md: "/assets/images/no-results-md/md_no_results_1.webp",
                  lg: "/assets/images/no-results-lg/lg_no_results_1.webp",
                },
                {
                  name: "no-results-2",
                  sm: "/assets/images/no-results-sm/sm_no_results_2.webp",
                  md: "/assets/images/no-results-md/md_no_results_2.webp",
                  lg: "/assets/images/no-results-lg/lg_no_results_2.webp",
                },
                {
                  name: "no-results-3",
                  sm: "/assets/images/no-results-sm/sm_no_results_3.webp",
                  md: "/assets/images/no-results-md/md_no_results_3.webp",
                  lg: "/assets/images/no-results-lg/lg_no_results_3.webp",
                },
                {
                  name: "no-results-4",
                  sm: "/assets/images/no-results-sm/sm_no_results_4.webp",
                  md: "/assets/images/no-results-md/md_no_results_4.webp",
                  lg: "/assets/images/no-results-lg/lg_no_results_4.webp",
                },
                {
                  name: "no-results-5",
                  sm: "/assets/images/no-results-sm/sm_no_results_5.webp",
                  md: "/assets/images/no-results-md/md_no_results_5.webp",
                  lg: "/assets/images/no-results-lg/lg_no_results_5.webp",
                },
                {
                  name: "no-results-6",
                  sm: "/assets/images/no-results-sm/sm_no_results_6.webp",
                  md: "/assets/images/no-results-md/md_no_results_6.webp",
                  lg: "/assets/images/no-results-lg/lg_no_results_6.webp",
                },
                {
                  name: "no-results-7",
                  sm: "/assets/images/no-results-sm/sm_no_results_7.webp",
                  md: "/assets/images/no-results-md/md_no_results_7.webp",
                  lg: "/assets/images/no-results-lg/lg_no_results_7.webp",
                }
              ],
            },
          },
        }
      },
    },
    footer: {
      links:[
        {
          title: "Privacy Policy",
          url: "https://www.fredericmalle.com/customer-service-privacy",
          target: "_blank"
        },
        {
          title: "Terms and Conditions",
          url: "https://www.fredericmalle.com/customer-service-terms",
          target: "_blank"
        },
        {
          title: "Accessibility",
          url: "https://www.fredericmalle.com/accessibility",
          target: "_blank"
        },
        {
          title: "Supplier Relations",
          url: "https://www.fredericmalle.com/customer-service-supplier",
          target: "_blank"
        },
        {
          title: "Do Not Sell or Share My Personal Information / Targeted Ads	",
          url: "#",
          target: "_self",
          class: "optanon-show-settings",
          parentClass: "x2"
        },
        {
          title: "Limit Use of My Sensitive Personal Information",
          url: "https://privacyportal.onetrust.com/webform/84bcbfa6-9d87-4573-b6f8-acfb1a7839a8/684a326a-3165-4734-befb-e93527349777?source=footer",
          target: "_blank",
          parentClass: "x2"
        },
      ]
    },
    privacyDisclaimer: (
      <>
        By clicking the buttons below, you agree to our processing of your personal information,
        including your responses to the questions in the quiz, which may be characterized
        as sensitive personal information depending on applicable law, for the purposes of
        providing personalized product recommendations. For more information about Frederic
        Malle, please view our <a href="https://www.fredericmalle.com/customer-service-privacy" className="privacy-policy-link" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
      </>
    ),
  },
};

export const PerfumeFinderConfigUK = {
  'en': {
    ...PerfumeFinderConfig['en'],
    productsURL: "https://www.fredericmalle.co.uk/product/19566/",
    flow: {
      components: {
        intro: {
          component: PFSplash,
          props: {
            nextFlow: "wizard",
            handleGenderSelect: (e, props, state) => {
              props.setGender(e.target.value);
              state.setFlow(props.nextFlow);

              trackGenderSelect(e);
            },
            handlePrivacyAgree: (e, props, state) => {
              if (!props.privacyAgree) {
                if (props.gender === 'man' || props.gender === 'woman' || props.gender === 'na') {
                  state.setFlow(props.nextFlow);
                }
              }
            },
            handleMarket: (e, props, state) => {
              if (props.markets === 'US') {
                state.setFlow(props.nextFlow);
              }
            },
          },
          subComponents: {
            selection: GenderSelector,
            disclaimer: PFDisclaimerAgree,
          }
        },
        wizard: {
          component: PFSelections,
          props: {
            nextFlow: "results",
            getStepData: (data, step, gender) => {
              return quizSelectionsPerStep(data, step, gender);
            },
            getPFData: (data, gender) => {
              return quizSelections(data, gender);
            },
            nextStep: (e, props, states, formikProps, noScrollTop) => {
              const handleNext = (props, states) => {
                props.setCurrentStep(props.currentStep + 1);
                if (states.wizardSelections) {
                  getProductScore(props.stepContent.category, states.wizardSelections, states.productScores, states.setProductScores, states.setPreviousStep);
                }
              }
              //breaks when no network connection
              props.currentStep < props.steps?.length ?
                handleNext(props, states) :
                (states.wizardSelections && props.setFlow('results'));
              !noScrollTop && (document.getElementById("root")).scrollTo({ top: 0, behavior: 'smooth' });
            },
            previousStep: (e, props, states, formikProps, noScrollTop) => {
              const handleBack = (props, states, formikProps, currentStep) => {
                props.setCurrentStep(props.currentStep - 1);
                if (states.wizardSelections) {
                  updateBack(states.wizardSelections, states.productScores, states.setProductScores, states.previousStep, states.setWizardSelections, formikProps, currentStep, states.setPreviousStep);
                }
              }
              const handleFirst = (props, states, formikProps, currentStep) => {
                if (states.wizardSelections) {
                  props.setFlow('intro');
                  updateFirst(states.wizardSelections, states.productScores, states.setProductScores, states.previousStep, states.setWizardSelections, formikProps, currentStep, states.setPreviousStep);
                }
              }
              props.currentStep > 1 ?
                handleBack(props, states, formikProps, props.currentStep) :
                handleFirst(props, states, formikProps, props.currentStep);
              !noScrollTop && (document.getElementById("root")).scrollTo({ top: 0, behavior: 'smooth' });
            },
            context: {
              select: 'category',
              list: 'answers',
              selectType: 'checkbox'
            }
          },
          subComponents: {
            wizard: ScoredWizard,
            selections: PFGridSelectItem,
            controls: PFSelectControls,
            group: ButtonGroup
          },
          controlsHeading: "Question",
          controlsDivider: "/",
          controlsSubHeaderCopy: " styles selected",
          controlsIcons: {
            prev: <Icon variant="arrow-left" />,
            next: <Icon variant="arrow-right" />,
          }
        },
        results: {
          component: PFResults,
          props: {
            nextFlow: "done",
          },
          subComponents: {
            hero: PFResultsDisplay,
            emails: PFEmailSlideout,
            reccomendations: PFPerfumeReccs,
            promos: PFPromos
          },
          sections: {
            hero: {
              title: "your selection",
              slogan: "L'avant-garde du parfum",
              sloganSubText: "Olfactive masterpieces signed by the world’s greatest perfumers",
              restartButtonText: "Start Over",
              component: PFResultsDisplay,
              selectionMap: {
                looks: {
                  count: 2
                },
                hair: {
                  count: 1
                },
                footwear: {
                  count: 2
                },
                transport: {
                  count: 1
                },
                underwear: {
                  count: 1
                },
                accessories: {
                  count: 3
                },
                environment: {
                  count: 1
                },
              },
            },
            emailSlideout: {
              taglines: {
                popover: true,
                checkbox: false,
                emailCard: "Join our mailing list",
                emailTagline:
                  <div className="text-no-margin-padding">
                    Sign up to hear more from us and receive information on Editions de Parfums Frédéric Malle new launches, products, events and offers.
                  </div>,
                emailPlaceholder: "Enter your email",
                confirmationTagline: "Thank you.",
                validationTagline: "Invalid email. Please try again.",
                tooltipTitle: "How do we use your data?",
                dataTooltip:
                  <div>
                    Editions de Parfums Frédéric Malle, a trading name of Estée Lauder Cosmetics Limited, will process your personal data in accordance with our <a href="https://www.fredericmalle.co.uk/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
                    By submitting your email address you are requesting to receive news and information by email about Editions de Parfums Frédéric Malle new launches, products, events and offers.  This will include product recommendations based on the results
                    of the perfume match quiz.  You can opt-out at any time by clicking the unsubscribe link in each email you receive.
                    For more information on our privacy practices and your rights please see our <a href="https://www.fredericmalle.co.uk/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
                  </div>
              },
            },
            reccomendations: {
              title: "Our suggestions for you",
              buttonText: "Discover"
            },
            promos: [
              {
                header: "Request your Samples",
                id: "RequestYourSamples",
                classes: "samples-request",
                image: samplesCover,
                link: "https://www.fredericmalle.co.uk/product/21165/55985/boxes/sample-service/sample-set#/sku/89913",
                actionText: "Order Sample",
                alt: "RequestYourSamples"
              },
              {
                header: "Discover in Stores",
                id: "DiscoverInStores",
                classes: "store-finder",
                image: storeFinderCover,
                link: "https://www.fredericmalle.co.uk/about/stores",
                actionText: "Find a Store",
                alt: "DiscoverInStores"
              }
            ],
            noResults: {
              title: "No result.",
              subTitle: "Select illustrations to find the perfume that matches your personality.",
              button: "Take the Quiz",
              slogan: "L'avant-garde du parfum",
              sloganSubText: "Olfactive masterpieces signed by the world’s greatest perfumers",
              imageSet: [
                {
                  name: "no-results-1",
                  sm: "/assets/images/no-results-sm/sm_no_results_1.webp",
                  md: "/assets/images/no-results-md/md_no_results_1.webp",
                  lg: "/assets/images/no-results-lg/lg_no_results_1.webp",
                },
                {
                  name: "no-results-2",
                  sm: "/assets/images/no-results-sm/sm_no_results_2.webp",
                  md: "/assets/images/no-results-md/md_no_results_2.webp",
                  lg: "/assets/images/no-results-lg/lg_no_results_2.webp",
                },
                {
                  name: "no-results-3",
                  sm: "/assets/images/no-results-sm/sm_no_results_3.webp",
                  md: "/assets/images/no-results-md/md_no_results_3.webp",
                  lg: "/assets/images/no-results-lg/lg_no_results_3.webp",
                },
                {
                  name: "no-results-4",
                  sm: "/assets/images/no-results-sm/sm_no_results_4.webp",
                  md: "/assets/images/no-results-md/md_no_results_4.webp",
                  lg: "/assets/images/no-results-lg/lg_no_results_4.webp",
                },
                {
                  name: "no-results-5",
                  sm: "/assets/images/no-results-sm/sm_no_results_5.webp",
                  md: "/assets/images/no-results-md/md_no_results_5.webp",
                  lg: "/assets/images/no-results-lg/lg_no_results_5.webp",
                },
                {
                  name: "no-results-6",
                  sm: "/assets/images/no-results-sm/sm_no_results_6.webp",
                  md: "/assets/images/no-results-md/md_no_results_6.webp",
                  lg: "/assets/images/no-results-lg/lg_no_results_6.webp",
                },
                {
                  name: "no-results-7",
                  sm: "/assets/images/no-results-sm/sm_no_results_7.webp",
                  md: "/assets/images/no-results-md/md_no_results_7.webp",
                  lg: "/assets/images/no-results-lg/lg_no_results_7.webp",
                }
              ],
            },
          },
        }
      },
    },
    footer: {
      links:[
        {
          title: "Privacy Policy",
          url: "https://www.fredericmalle.co.uk/privacy-policy",
          target: "_blank"
        },
        {
          title: "Terms and Conditions",
          url: "https://www.fredericmalle.co.uk/customer-service-terms",
          target: "_blank"
        },
        {
          title: "Manage Cookies",
          url: "#",
          target: "_self",
          class: "optanon-show-settings",
        },
        {
          title: "Corporate Statements",
          url: "https://www.fredericmalle.co.uk/corporate-statement",
          target: "_blank"
        }
      ]
    },
    privacyDisclaimer: (
      <>
      By clicking the buttons below and making your selections in the next pages, you understand 
      we will process your responses in the quiz for the purpose of providing personalized product recommendations.  
      For more information about Éditions de Parfums Frederic Malle, please view our  <a href="https://www.fredericmalle.co.uk/privacy-policy" className="privacy-policy-link" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
      </>
    )
  },
};

  export const marketConfigs = {
    fr: {
      productsURL: "https://www.fredericmalle.eu/fr-e-uf/product/19566/",
      storeURL: "https://www.fredericmalle.eu/about/stores#forcedLocale",
      samplesURL: "https://www.fredericmalle.eu/fr-e-uf/product/21165/55985/boxes/sample-service/sample-set#/sku/89913"
    },
    uk: {
      productsURL: "https://www.fredericmalle.co.uk/product/19566/",
      storeURL: "https://www.fredericmalle.co.uk/about/stores",
      samplesURL: "https://www.fredericmalle.co.uk/product/21167/55985/custom-sets/sample-service#/sku/89913"

    },
    us: {
      productsURL: "https://www.fredericmalle.com/product/19566/",
      storeURL: "https://www.fredericmalle.com/about/stores",
      samplesURL: "https://www.fredericmalle.com/product/21165/55985/boxes/sample-service/sample-set#/sku/89913"
    },
    eu: {
      productsURL: "https://www.fredericmalle.eu/product/19566/",
      storeURL: "https://www.fredericmalle.eu/about/stores#forcedLocale",
      samplesURL: "https://www.fredericmalle.eu/product/21165/55985/coffrets/sample-service/sample-set#/sku/89913"
    }
  }