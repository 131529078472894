import * as Yup from 'yup';
import { validationErrors } from '../constants/validation';
import "yup-phone";

export const validationPatterns = {
    required: Yup.string().required(validationErrors.requiredField),
    email: Yup.string().email('Please enter a valid email'),
    requiredEmail: Yup.string().email('Please enter a valid email').required(validationErrors.requiredField),
    PFRequiredEmail: (message) => (Yup.string().email(message).required(message)),
    requiredPhone: Yup.string().phone().required(validationErrors.requiredField),
    agreement: Yup.boolean().oneOf([true],'You must agree to our Terms and Privacy Policy.'),
    check1: Yup.array().min(1,'You Must Select at least one option'),
    check2: Yup.array().min(1,'You Must Select at least one option').max(2,'You can only choose 2 options'),
    check5: Yup.array().min(1,'You Must Select at least one option').max(5,'You can only choose 5 options')
};

export const yupSchemas ={
    perfumeFinderSchema: Yup.object().shape({
      looks: validationPatterns.check2,
      hair: validationPatterns.check1,
      footwear: validationPatterns.check2,
      transport: validationPatterns.check1,
      underwear: validationPatterns.check1,
      accessories: validationPatterns.check5,
      environment: validationPatterns.check1,
    }),
    emailSchema: (message) => Yup.object().shape({
      email: validationPatterns.PFRequiredEmail(message),
    }),
}