import React, { useContext } from "react";
import WizardContent from "./WizardContent";
import PropTypes from "prop-types";
import { AppContext } from "../../data/stores/app.store";

/**
 * A WizardGroup component *does something*. 
 */

const WizardGroup = (props) => {
    const Group = props.group;
    const { appData  } = useContext(AppContext);
    
    return (
      <div className={"image-grid-wrapper" + " wizard-step-" + props.currentStep}>
        {props.steps && props.steps.length > 0 && 
          props.steps.map((item, i) => {
            const stepContent = item[props.context.list];
            return ((i === (props.currentStep - 1)) &&
              <Group key={item[props.context.select]} as="ul" className="wizard-selections" role="list" id={appData?.semanticList?.stepName + "Step" + props.currentStep}>
                <WizardContent context={props.context} step={item} content={stepContent} item={props.component} breakpoints={appData?.breakpoints ?? props?.breakpoints} />
              </Group>
            );
          })
        } 
      </div>
    );
}

WizardGroup.propTypes = {
  /** String */
  group: PropTypes.object,
  /** String */
  currentStep: PropTypes.number,
  /** String */
  steps: PropTypes.array,
  /** String */
  context: PropTypes.object,
  /** String */
  component: PropTypes.func,
}

export default WizardGroup;