import React, { useContext, useEffect, useRef } from "react";
import { Button, Row, Col } from "react-bootstrap";
import Icon from "../UI/Icon";
import { Formik, Field, } from "formik";
import PropTypes from "prop-types";
import { useState } from "react";
import { Form } from "react-bootstrap";
import { AppContext } from "../../data/stores/app.store";
import { compileQuizResults } from "../../utils/emailUtils";
import { FetchData } from "../../data/FetchData";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

/**
 * A PFEmailInput component renders an email input form. 
 *   - It renders a Formik form with bootstrap Button.
 *   - It validates using Yup validation schema.
 */

const PFEmailInput = (props) => {
    const { appData, language, market } = useContext(AppContext);
    const submitEmail = appData.endpoints?.submitEmail();
    const emailFormRef = useRef(null);

    const messageInLanguage = appData.flow?.components?.results?.sections?.emailSlideout?.taglines?.validationTagline

    useEffect(() => {
        return () => {
        };
    }, [props.confirmed]);

    const handleSetConfirmed = (confirmed) => { props.setConfirmed(confirmed) };


    return (
        <div className="email-field-wrapper" id={props.id} >
            <Row className="fs-6 mb-4 mx-0 email-slideout-form">
                {props.emailTagline}
            </Row>
            <Formik
                initialValues={{
                    ...props.emailFields,
                    opt_in: true
                }}
                onSubmit={async (values, isValid) => {
                    if (isValid) {
                        let upperCaseMarket = market.toUpperCase();
                        let quizRes = compileQuizResults(props.selections, props.productReccs, values, upperCaseMarket);
                        FetchData(submitEmail, null, null, null, [], null, null, false, language, quizRes, handleSetConfirmed);
                    }
                }}
                validationSchema={
                    // props.validation[language + "EmailSchema"] || props.validation?.emailSchema
                    props.validation?.emailSchema(messageInLanguage)
                }
            >
                {({ values,
                    errors,
                    touched,
                    isValid,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldTouched,
                    setFieldValue,
                    isSubmitting }) => {

                    const handleOptInChange = (e) => {
                        setFieldValue('opt_in', e.target.checked);
                    };

                    return (
                        <Form className="email-slideout-form" ref={emailFormRef}>
                            <Row className="w-100">
                                <Col xs={9} md={11}>
                                    <Field
                                        type="text"
                                        name="email"
                                        placeholder={props.emailPlaceholder}
                                        className="w-100 h-100 placeholder-fm"
                                        value={values.email}
                                        onChange={handleChange}
                                        onBlur={
                                            (e) => {
                                                handleBlur(e);
                                                setFieldTouched('email', true, true);
                                            }
                                        }
                                        disabled={props.disabled}
                                    />
                                </Col>
                                <Col className="px-0">
                                    <Button
                                        type="submit"
                                        variant="primary"
                                        onClick={handleSubmit}
                                        disabled={props.checkbox ? (!isValid || !values.opt_in || values.email === '') : !isValid}
                                        data-testid="submit-button"
                                    >
                                        <Icon variant="arrow-right"></Icon>
                                    </Button>
                                </Col>
                            </Row>
                            {errors.email && touched.email &&
                                <Row className="w-100">
                                    <Col xs={9} md={11}>
                                        <div className="email-error-message text-danger text-end">{errors.email}</div>
                                    </Col>
                                </Row>
                            }
                            <Row className="mt-4 w-100">
                                <Col md={11}>
                                    <Row className="d-flex justify-content-start">
                                        <Col>
                                            {props.checkbox && (
                                                <div className="d-flex">
                                                    <Field
                                                        type="checkbox"
                                                        name="opt_in"
                                                        className="fm-checkbox"
                                                        onChange={handleOptInChange}
                                                    />
                                                    <p className="fm-checkbox-label" htmlFor="opt_in" >
                                                        {props.checkboxTagline}
                                                    </p>
                                                </div>
                                            )}
                                            {props.popover && (
                                                <OverlayTrigger
                                                    placement="auto-end"
                                                    trigger="click"
                                                    container={emailFormRef}
                                                    overlay={
                                                        <Tooltip                                                 
                                                            className="privacy-tooltip"
                                                            id="tooltip"
                                                        >
                                                            {props.dataTooltip}
                                                        </Tooltip>
                                                    }>
                                                    <Button className="text-no-margin-padding" variant="link">{props.tooltipTitle}</Button>
                                                </OverlayTrigger>
                                            )}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
}

PFEmailInput.propTypes = {
    checkboxTagline: PropTypes.string,
    emailTagline: PropTypes.any,
    emailPlaceholder: PropTypes.string,
    emailFields: PropTypes.shape({
        email: PropTypes.string,
        opt_in: PropTypes.bool,
    }),
    setConfirmed: PropTypes.func,
    validation: PropTypes.object,
    productReccs: PropTypes.array,
    selections: PropTypes.object,
}

export default PFEmailInput;