import React from "react";
import { assets } from "../../assets/assets";

const PFResultSounds = ({wizardSelections}) => {
  if(wizardSelections?.transport.length > 0){
    const bikesNoMotor = wizardSelections.transport[0].name.indexOf('bike') > 0 || wizardSelections.transport[0].name.indexOf('scooter') > 0 || wizardSelections.transport[0].name.indexOf('skateboard') > 0;

    const sportscars = (wizardSelections.transport[0].name.indexOf('retro') > 0 || wizardSelections.transport[0].name.indexOf('cabriolet') > 0 || wizardSelections.transport[0].name.indexOf('vintage') > 0 || wizardSelections.transport[0].name.indexOf('tesla') > 0 || wizardSelections.transport[0].name.indexOf('sports_car') > 0);

    const animals = wizardSelections.transport[0].name.indexOf('horses') > 0;

    return (
      (wizardSelections?.transport.length > 0 && wizardSelections?.transport[0] &&  
      (bikesNoMotor)? //Bikes and Non Motot
        (<audio src={assets.bike} loop={false} autoPlay={true} width="2" height="0"></audio>):
          (sportscars)? // Sports Cars - Wroom
            (<audio src={assets.sportscar} loop={false} autoPlay={true} width="2" height="0"></audio>):
              (animals)? // No sound for animals
                <></>:
                (<audio src={assets.car} loop={false} autoPlay={true} width="2" height="0"></audio>) // Default is horn sounding
      )
    );
  }else{
    return(<></>)
  }
}

export default PFResultSounds;