import car from "./sounds/fm-sound-02-car-beep.mp3";
import sportscar from "./sounds/fm-sound-01-sportscar.mp3";
import bike from "./sounds/fm-sound-03-pedal-bike.mp3";
import missingProduct from "./images/fm_pf_product_missing.webp";
import missingPackaging from "./images/fm_pf_package.webp";

export const assets = {
  car: car,
  sportscar: sportscar,
  bike: bike,
  fmMissingProduct: missingProduct,
  fmMissingPackaging: missingPackaging
}