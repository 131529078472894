import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

/**
 * An ImageRow component renders the row of square tile images. 
 *   - It is responsive with sm, md, and lg breakpoints.
 */

const ImageRow = (props) => {

    const [smMaxWidth, setSmMaxWidth] = useState(0);
    const [mdMaxWidth, setMdMaxWidth] = useState(0);
    const [smSize, setSmSize] = useState(0);
    const [mdSize, setMdSize] = useState(0);
    const [lgSize, setLgSize] = useState(0);
    const [imageData, setImageData] = useState([]);

    useEffect(() => {
        props.smMaxWidth && setSmMaxWidth(props.smMaxWidth);
        props.mdMaxWidth && setMdMaxWidth(props.mdMaxWidth);
        props.smSize && setSmSize(props.smSize);
        props.mdSize && setMdSize(props.mdSize);
        props.lgSize && setLgSize(props.lgSize);
        props.imageSet && setImageData(props.imageSet);

        return () => {
            // ** ALWAYS cleanup
            setImageData([]);
            setSmMaxWidth(0);
            setMdMaxWidth(0);
            setSmSize(0);
            setMdSize(0);
            setLgSize(0);
        };
    }, []);

    return (
        <div className="image-row">
            {imageData.map((image) => {
                const srcset = image.sm + " " + smSize + "w, " + image.md + " " + mdSize + "w, " + image.lg + " " + lgSize + "w";
                const sizes = "(max-width: " + smMaxWidth + "px) " + smSize + "px, (max-width: " + mdMaxWidth + "px) " + mdSize + "px, " + lgSize + "px";
                return (
                    <img
                        key={"tile-image-" + image.name}
                        className="w-unset"
                        srcSet={srcset}
                        sizes={sizes}
                        src={image.lg}
                        alt="A no results background tile depicting a style."
                    />
                );
            })}
        </div>
    );
}

ImageRow.propTypes = {
    smMaxWidth: PropTypes.number,
    mdMaxWidth: PropTypes.number,
    smSize: PropTypes.number,
    mdSize: PropTypes.number,
    lgSize: PropTypes.number,
    imageSet: PropTypes.array,
}

export default ImageRow;