export const timedCache = (time, cacheStore, setRefreshData) => {
    const tokenTime = localStorage.getItem('d_expires');
    const dateNow = Date.now();
    const tokenExpire = time;
    const getHours = (milliseconds) => {
      let seconds = Math.floor(milliseconds / 1000);
      let minutes = Math.floor(seconds / 60);
      let hours = Math.floor(minutes / 60);
      return Math.floor(hours);
    }
    const compareTime = getHours(dateNow - tokenTime);

    if(compareTime >= tokenExpire){
      cacheStore.forEach(element => {
        localStorage.removeItem(element);
      });
      setRefreshData(true);
    } 
}