import React from "react";
import { Button, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import PFSlogan from "./PFSlogan";
import ImageRow from "../UI/ImageRow";
import { useSearchParams } from 'react-router-dom';

/**
 * A NoResults component renders the UI for when the user makes no selections and thus has no results. 
 *   - It renders images individually, responsively.
 */

const PFNoResults = (props) => {

    const [searchParams] = useSearchParams();

    const handleStartOver = () => {
        const thisMarket = searchParams.get("market");
        thisMarket ? (window.location.href = "/?market=" + thisMarket):(window.location.href = "/");
    };
    
    return (
        <div className="no-selections-page px-0 mx-0">
            <Row className="w-100 mx-0">
                <h2 className="quiz-title">{props.data?.title}</h2>
            </Row>
            <Row className="text-center w-100 mx-0">
                <p className="mb-0">{props.data?.subTitle}</p>
            </Row>
            <Row className="justify-content-center mx-0 w-100">
                <Button
                    className="button-light"
                    variant="outline-light"
                    onClick={handleStartOver}
                >
                    {props.data?.button}
                </Button>
            </Row>
            <Row className="w-100 mx-0">
                <ImageRow
                    smMaxWidth={props.breakpoints?.sm?.maxWidth}
                    mdMaxWidth={props.breakpoints?.md?.maxWidth}
                    smSize={props.breakpoints?.sm?.imageSize}
                    mdSize={props.breakpoints?.md?.imageSize}
                    lgSize={props.breakpoints?.lg?.imageSize}
                    imageSet={props.data?.imageSet}
                ></ImageRow>
            </Row>
            <Row className="bottom-sticky-slogan w-100 mx-0">
                <PFSlogan data={props.data} />
            </Row>
        </div>
    );
}

PFNoResults.propTypes = {
    data: PropTypes.object,
    breakpoints: PropTypes.object,
}

export default PFNoResults;