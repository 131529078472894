import React, { useContext } from "react";
import { AppContext, PFContext } from "../../data/stores/app.store";
import { ButtonGroup, ToggleButton } from "react-bootstrap";
import PropTypes from "prop-types";

/**
 * A GenderSelector component renders selection of different genders. 
 *   - It displays selectable elements of type radio - single select.
 *   - It renders a bootstrap ToggleButton.
 */

const GenderSelector = (props) => {
    const { brandPrefix, appData  } = useContext(AppContext);

    const  { setFlow } = useContext(PFContext);

    return (
      <div className={brandPrefix + "-gender-selection"}>
        <ButtonGroup>
          {
            props.selections.options.map((selection, i) => {
              const genderCasing = selection.value[0].toUpperCase() + selection.value.substring(1).toLowerCase();
              return( 
                <ToggleButton 
                  key={"gender-" + i + (props.id ? props.id : "")}
                  id={appData?.semanticList.genderSelector + genderCasing + (props.id ? props.id : "")}
                  type="radio"
                  variant="outline-light" 
                  onChange={(e)=>props.handleGenderSelect(e,props,{setFlow:setFlow})}  
                  value={selection.value}
                  name={"genderSelect" + (props.id ? props.id : "")}
                  checked={props.gender === selection.value}
                >
                  {selection.label}
                </ToggleButton>
              )
            })
          }
        </ButtonGroup>
      </div>
    );
}

GenderSelector.propTypes = {
  /** Passed in id */
  id: PropTypes.string,
  /** Passed in gender */
  gender: PropTypes.string,
  /** Passed in gender select handlers */
  handleGenderSelect: PropTypes.func,
  /** Passed in selection data */
  selections: PropTypes.shape({
    /** Passed in option data */
    options: PropTypes.arrayOf(PropTypes.shape({
      /** Passed in label */
      label: PropTypes.string,
      /** Passed in value */
      value: PropTypes.string
    }))
  }),
}


export default GenderSelector;