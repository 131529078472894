import { BrandStore } from "../data/stores/BrandStore";

export const refMarket = ( refs, source) => {
  let refMarket = false;
  for (let market in refs) {
    const refLastChar = refs[market].url.slice(-1);
    const sourceLastChar = source.slice(-1);
    const cleanRef = refLastChar === "/" ? refs[market].url.slice(0,-1): refs[market].url;
    const cleanSource = sourceLastChar === "/" ? source.slice(0,-1):source;
    if(refs.hasOwnProperty(market) && source.indexOf(cleanRef) > -1){
      refMarket = market;
    }
  }
  return refMarket;
}

export const determineMarket = (searchParams) => {
  const markets = BrandStore.markets;
  const sourceUrl = window.document.referrer;
  const getRefMarket = refMarket(markets,sourceUrl);

  let thisMarket = 'fr';

  const host = window.location.host;
  if(searchParams.get("market")){
    const targetMarket = searchParams.get("market");
    if(markets.hasOwnProperty(targetMarket)){
      thisMarket = targetMarket
    }else{
      thisMarket = "eu"
    }
  }else if(getRefMarket === "fr"){
    thisMarket = "fr";
  }else if(host.indexOf('edp.fredericmalle.com') > -1){
    thisMarket = 'us';
  }else if(host.indexOf('edp.fredericmalle.co.uk') > -1){
    thisMarket = 'uk'
  }else if(host.indexOf('edp.fredericmalle.eu') > -1){
    thisMarket = 'eu'
  }else{
    thisMarket = 'fr';
  }

  return thisMarket;

}