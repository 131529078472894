export const compileQuizResults = (selections, productReccs, values, market) => {
    let quizResults = values;

    let skus = [];
    productReccs.map((product) => (
        skus.push(product.sku)
    ))

    // quizResults["image_uris"] = image_uris;
    quizResults["skus"] = skus;
    quizResults["market"] = market;

    //console.log(quizResults);
    return quizResults;
}
