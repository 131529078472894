export const trackProductClick = (product, i) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });  
    window.dataLayer.push({
      'event': 'eventload',
      'eventCategory': 'finder_results',
      'eventAction': 'results',
      'eventLabel': 'product_click',
      'ecommerce': {
        'click': {
          'actionField': { 'list': 'results' },
          'products': [{
            'id': 'PROD' + product.sku,
            'position': i + 1,
          }]
        }
      }
    });
  };