import React, { useContext } from "react";
import { AppContext } from "../../data/stores/app.store";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";

/**
 * A PFDisclaimerAgree component renders the disclaimer agreement. 
 * 
 */

const PFDisclaimerAgree = (props) => {
    const { brandPrefix, market  } = useContext(AppContext);

    return ( (market=== 'us', 'fr', 'eu' || props.displayProp) &&
      <div className={(brandPrefix || props.brandPrefix) + "-privacy-disclaimer"}> 
        <Form.Group className="disclaimer-action-block">
          <Form.Label>{props.disclaimer.label}</Form.Label>
        </Form.Group>
      </div>
    );
}

PFDisclaimerAgree.propTypes = {
  disclaimer: PropTypes.object,
}

export default PFDisclaimerAgree;