import React, { useState, useContext } from "react";
import { Button, Offcanvas, Card, Row, Col, Container } from "react-bootstrap";
import Icon from "../UI/Icon";
import PropTypes from "prop-types";
import PFConfirmation from "./PFConfirmation";
import PFEmailInput from "./PFEmailInput";
import { AppContext } from "../../data/stores/app.store";


/**
 * A PFEmailSlideout component renders a card with button that opens a slideout with email form. 
 *   - It renders a bootstrap Card.
 *   - It renders a bootstrap Offcanvas (slideout).
 *   - The slideout either contains a PFEmailInput component or PFConfirmation based on state.
 */

const PFEmailSlideout = (props) => {
    const [show, setShow] = useState(false);
    const [confirmed, setConfirmed] = useState(false);
    const {appData} = useContext(AppContext);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    return (
        <Container className="vertical-position-adjust">
          <div className="email-results-wrapper">
            <Card className="email-slideout py-3 px-2 rounded-0 mb-5" id={appData?.semanticList.emailEntry}>
                <Row className="px-1">
                    <Col xs={9} className="d-flex align-items-center justify-content-center">
                        {props.emailCard}
                    </Col>
                    <Col>
                        <Button variant="primary" onClick={handleShow} aria-label={props.emailCard}>
                            <Icon variant="email"></Icon>
                        </Button>
                    </Col>
                </Row>
                <Offcanvas className="pf-offcanvas-height" placement="bottom" show={show} onHide={handleClose}>
                    <Offcanvas.Header className="justify-content-end" closeButton>
                    </Offcanvas.Header>
                    <Offcanvas.Body className="d-flex justify-content-center p-0">
                        <Row className="py-0 mx-3">
                            <Col xs={12} className="px-0">
                                <div>
                                    {!confirmed ?
                                        <PFEmailInput
                                            id={appData?.semanticList.emailResult}
                                            validation={props.validation}
                                            emailFields={props.emailFields}
                                            confirmed={confirmed}
                                            setConfirmed={setConfirmed}
                                            emailTagline={props.emailTagline}
                                            checkboxTagline={props.checkboxTagline}
                                            dataTooltip={props.dataTooltip}
                                            tooltipTitle={props.tooltipTitle}
                                            checkbox={props.checkbox}
                                            popover={props.popover}
                                            emailPlaceholder={props.emailPlaceholder}
                                            selections={props.selections} 
                                            productReccs={props.productReccs}    
                                            disabled={props.disabled}                            
                                        ></PFEmailInput>
                                        :
                                        <PFConfirmation
                                            confirmationTagline={props.confirmationTagline}
                                        />
                                    }
                                </div>
                            </Col>
                        </Row>
                    </Offcanvas.Body>
                </Offcanvas>
            </Card>
          </div>
        </Container>
    );
}

PFEmailSlideout.propTypes = {
    checkboxTagline: PropTypes.string,
    confirmationTagline: PropTypes.string,
    emailCard: PropTypes.any,
    emailPlaceholder: PropTypes.string,
    emailTagline: PropTypes.any,
    emailFields: PropTypes.shape({
      email: PropTypes.string,
      opt_in: PropTypes.bool,
    }),
    validation: PropTypes.object,
    productReccs: PropTypes.array,
    selections: PropTypes.object,
  }
  
export default PFEmailSlideout;
