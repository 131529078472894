import React, {useContext}from "react";
import Button from "react-bootstrap/Button";
import PropTypes from "prop-types";
import { AppContext } from "../../data/stores/app.store";
import { trackPromotionClick } from "../../analytics/PFPromo_ga";

/**
 * PFPromo component displays the websites promotions. 
 *   - It renders the header, image, image alt text, 
 *     button, button text, and button link.
 */

const PFPromo = (props) => {
    const {appData} = useContext(AppContext);

    return (
        <div className={"promo " + props.id}>
            <div className={"promo-left" + " " + props.classes}>
                <div className="promo-left-inside">
                    <h3 className="promo-header" id={appData?.semanticList.discoverHeader + props.id}>{props.header}</h3>
                    <Button 
                    href={props.link} 
                    target="_blank" 
                    className="promo-btn" id={appData?.semanticList.discoverButton + props.id}
                    onClick={() => trackPromotionClick(props)}
                    >
                        {props.actionText}
                    </Button>
                </div>
            </div>
            <div className={"promo-right" + " " + props.classes}>
                <img src={props.image} alt={props.alt} className="promo-img" id={appData?.semanticList.discoverImage + props.id}/>
            </div>
                <div className="promo-mobile">
                    <h3 className="promo-header">{props.header}</h3>
                </div>
        </div>
      );
}

PFPromo.propTypes = {
    header: PropTypes.string,
    id: PropTypes.string,
    link: PropTypes.string,
    actionText: PropTypes.string,
    image: PropTypes.string,
    alt: PropTypes.string,
  }
export default PFPromo;