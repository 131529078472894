import React, {useContext}from "react";
import PropTypes from 'prop-types'
import PFSlogan from "./PFSlogan";
import Icon from "../UI/Icon";
import { AppContext } from "../../data/stores/app.store";
import { useSearchParams } from 'react-router-dom';

/**
 * A PFResultsDisplay component renders the results Collage. 
 *   - It renders a Hero with a collage of selected options.
 */

const PFResultsDisplay = (props) => {
  const {appData} = useContext(AppContext);
  const [searchParams] = useSearchParams();

  if (!props.selections) return null;

  const handleStartOver = () => {
    const thisMarket = searchParams.get("market");
    thisMarket ? (window.location.href = "/?market=" + thisMarket):(window.location.href = "/");
  };

  // const imageSelection = category.quizSelection[0].toUpperCase() + category.quizSelection.substring(1).toLowerCase();

  return (
    <div className="results-display">
      <h2 className="quiz-title" id={appData?.semanticList?.yourStyleResult}>{props.data?.title}</h2>
      <div className="quiz-start-over-button-container">
        <button className="quiz-start-over-button" id={appData?.semanticList?.restartButton} onClick={handleStartOver}>
          {props.data?.restartButtonText}
          <Icon className="ms-2" variant="rotate-right"></Icon>
        </button>
      </div>
      <div className="image-grid-results">
        {Object.entries(props.selections).map(([category, quizSelection],i) => {
          if(quizSelection[0]){ 
            // Takes first image for each category
            const imageSelect = quizSelection[0];
            const imageSelectResponsiveImages = quizSelection[0].images;
            if(category !== 'transport'){
              return (
                quizSelection.map((item,i)=>{
                  if(i <= props.data.selectionMap[category].count - 1){
                    return(
                      <div key={item.name} className={`image-item ${category} item-${i+1}`} id={appData?.semanticList?.resultImageSelection + `${category}` + i}>
                        <img src={item?.images.original} alt={item.name} />
                      </div>
                    );
                  }
                })
              );
            }else{
              return (
                <div key={imageSelect.name} className={`image-item ${category}`}>
                  <img src={"assets/images/" + imageSelect.name + ".webp"} alt={imageSelect.name} />
                </div>
              );
            }
          }else{
            return (
              <div key={"noSelection" + i} className={`image-item ${category}`}>

              </div>
            );
          }
        })}
      </div>
      <div>
        <PFSlogan data={props.data} />
      </div>
    </div>
  );
};

PFResultsDisplay.propTypes = {
  selections: PropTypes.object,
  data: PropTypes.shape({
    title: PropTypes.string,
    restartButtonText: PropTypes.string
  }),

};

export default PFResultsDisplay;

