export const trackResultsLoad = (finalTop3Products) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });  
    window.dataLayer.push({
      'event': 'eventload',
      'eventCategory': 'finder_results',
      'eventAction': 'results',
      'eventLabel': 'product_results',
      'ecommerce': {
        // 'currencyCode': '{{currency}}',
        'impressions': finalTop3Products.map((product, index) => ({
          'id': 'PROD' + product.sku,
          'list': 'results',
          'position': index + 1,
        })),
      },
    });
  };