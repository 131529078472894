import React, { useEffect, useState, useContext } from "react";
import PFHeader from "./PFHeader";
import PFFooter from "./PFFooter";
import { AppContext, PFContext } from "../../data/stores/app.store";
import PerfumeFinderWizard from "./PerfumeFinderWizard";
import AppLayout from "../Layouts/AppLayout";
import { Container } from "react-bootstrap";
import _ from "lodash";

export default function PerfumeFinder(props) {
    const [flow, setFlow] = useState('');
    const [wizardSelections, setWizardSelections] = useState({});
    const [productScores, setProductScores] = useState({});
    const [bgColor, setBGColor] = useState('bg-black');

    const { brandPrefix } = useContext(AppContext);

    const thisState = {flow, setFlow, bgColor, setBGColor, productScores, setProductScores};

    const ThisPFHeader = (
        <PFHeader store={props.store} />
    );

    const ThisPFFooter = (
        <PFFooter store={props.store} />
    );

    useEffect(() => {
        setFlow('intro');
        const initPerfumeFinderFields =  _.cloneDeep(props.store.perfumeFinderFields);
        setWizardSelections({...initPerfumeFinderFields});
        //setProductScores(props.store.productScores); // Default scores come from list
        return () => {
          setFlow('');
        };
    }, []);

    return (
        <AppLayout
            header={ThisPFHeader}
            footer={flow !== "wizard" ? ThisPFFooter:<></>}
            headerClasses="navbar-header"
            footerClasses="navbar fm-footer"
            backgroundColor={bgColor}
            containerClasses={brandPrefix + "-" +  flow}
        >
          <PFContext.Provider
            value={{
              flow: flow,
              setFlow: setFlow,
              wizardSelections: wizardSelections, 
              setWizardSelections: setWizardSelections,
              productScores: productScores, 
              setProductScores: setProductScores,
              bgColor:bgColor, 
              setBGColor:setBGColor
            }}
          >
          <div className={brandPrefix + "-app-wrapper app-wrapper"}>
            {/* Render Landing and Quiz */}
            {flow !== 'results' &&
              <Container className="fm-container">
                <PerfumeFinderWizard store={props.store} config={props.store} {...thisState} />
              </Container>
            }
            {/* Render Results */}
            {flow === 'results' &&
              <PerfumeFinderWizard store={props.store} config={props.store} {...thisState} />
            }
          </div>
        </PFContext.Provider>
        </AppLayout>
    );
};
