import React, { useEffect, useState, useContext } from "react";
import { PFContext, AppContext } from "../../data/stores/app.store";
import { topThree } from "../../utils/scoringUtils";
import PropTypes from "prop-types";
import { Container } from "react-bootstrap";
import PFResultSounds from "./PFResultSounds";
import PFNoResults from "./PFNoResults";
import { trackResultsLoad } from "../../analytics/PFResults_ga";

/**
 * A PFResults component renders the results page. 
 *   - It gathers the top 3 products by score.
 *   - It renders a Hero, Emails, Recommendations, and Promos component.
 */

const PFResults = (props) => {
  const Hero = props.components.hero;
  const Emails = props.components.emails;
  const Reccomendations = props.components.reccomendations;
  const Promos = props.components.promos;
  const { deviceLocation } = useContext(AppContext);
  const { wizardSelections, productScores} = useContext(PFContext);
  const [topThreeProducts, setTopThreeProducts] = useState([]); 
  const [hasResults, setHasResults] = useState(false);
  const [productsData, setProductsData] = useState([]);

  useEffect(() => {
    if(Array.isArray(props.products) && props.products.length > 0){
      setProductsData(props.products);
      const topThreeScores = (productScores ?? props.productScores) && topThree(productScores ?? props.productScores);
      const newTop3 = {};
      topThreeScores?.length > 0 && topThreeScores.map(product => {
        return Object.assign(newTop3, {[product[0]]: product[1]})
      });
      const finalTop3Products = props.products.filter(product => newTop3.hasOwnProperty(product.sku))
      setTopThreeProducts(finalTop3Products);
  
      if (finalTop3Products.length > 0) {
        trackResultsLoad(finalTop3Products);
      }
    }
    let quizResultsTracker = 0;
    const theseSelections = wizardSelections || props.wizardSelections;
    theseSelections && Object.keys(theseSelections).map((step) => {
      if(theseSelections[step]?.length > 0){
        return quizResultsTracker = quizResultsTracker + 1;
      }else{
        return(<></>);
      }
    });
    quizResultsTracker > 0 ? setHasResults(true):setHasResults(false);
    return () => {
      // ** ALWAYS cleanup
      setTopThreeProducts([]);
      setHasResults(false);
    };
  }, [wizardSelections, productScores, props.products]);

  if (hasResults) {
    return (
      <div className="results-wrapper">
        <div className="results-hero">
          <Container>
            <Hero 
              selections={wizardSelections || props.wizardSelections} 
              data={props.sections.hero}
            />
          </Container>
          <Emails
            disabled={props.disabled}
            validation={props.validation}
            emailFields={props.emailFields}
            {...props.sections.emailSlideout.taglines}
            selections={wizardSelections} 
            productReccs={topThreeProducts}
          />
        </div>
        <div className="perfume-recs-wrapper">
          <Reccomendations 
            section={props.sections.reccomendations}
            productsData={productsData}
            productReccs={topThreeProducts}
            displayVariant={"ml100"}
          />
        </div>
        {deviceLocation !== 'store' && 
          <div className="promos-wrapper">
            <Promos data={props.sections.promos} />
          </div>
        }
        <PFResultSounds wizardSelections={wizardSelections || props.wizardSelections} />
      </div>
    );
  } else {
    return (
      <PFNoResults
        data={props.sections.noResults}
        breakpoints={props.store?.breakpoints}
      ></PFNoResults>
    );
  }
}

PFResults.propTypes = {
  components: PropTypes.object,
  emailFields: PropTypes.shape({
    email: PropTypes.string,
    opt_in: PropTypes.bool,
  }),
  sections: PropTypes.object,
  validation: PropTypes.object
}

export default PFResults;