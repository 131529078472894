export const validationErrors = {
    requiredField: "This field is required.",
};

export const pageLevelErrors = {
    requiredFields: {
        header: "You have missed some required fields, please correct them before proceeding to review.",
        body: "The following fields are missing:",
    },
}
