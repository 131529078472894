export const getProductScore = (step, selections, totalScores, setProductScores, setPreviousStep) => {
  for(let j = 0; j < selections[step].length; j++){
    for(let i = 0; i < selections[step][j].productScores.length; i++){
      let scores = selections[step][j].productScores[i];
      let product = scores.product.toString();
      let value = scores.score;
      //NOTE FOR FUTURE: Compare to old selects
      if (totalScores.hasOwnProperty(product)) {
        totalScores[product] += value;
      }
    }
  }
  setPreviousStep(step);
  setProductScores(totalScores);
}

export const updateBack = (selections, totalScores, setProductScores, previousStep, setSelections, formikProps, currentStep, setPreviousStep) => {
  if(selections[previousStep]){
    for(let j = 0; j < selections[previousStep].length; j++){
      for(let i = 0; i < selections[previousStep][j].productScores.length; i++){
        let scores = selections[previousStep][j].productScores[i];
        let product = scores.product.toString();
        let value = scores.score;
        if (totalScores.hasOwnProperty(product)) {
          totalScores[product] -= value;
        }
      }
    }
  }
  const currSelects = {...selections};
  if (currSelects.hasOwnProperty(previousStep)){
    const currStep = Object.keys(currSelects);
    const stepToClear = currStep[currentStep - 1];
    const previousIndex = currStep.indexOf(previousStep);
    const resetPreviousStep = currStep[previousIndex - 1];
    currSelects[previousStep] = [];
    currSelects[stepToClear] = [];
    formikProps && formikProps.setFieldValue(previousStep,[]);
    formikProps && formikProps.setFieldValue(stepToClear,[]);
    setPreviousStep(resetPreviousStep);
    setSelections(currSelects);
  } 
  setProductScores(totalScores);
}

export const updateFirst = (selections, totalScores, setProductScores, previousStep, setSelections, formikProps, currentStep, setPreviousStep) => {
  //debugger;
  const currSelects = {...selections};
  const currStep = Object.keys(currSelects);
  for(let step in currStep){
    const stepToClear = currStep[step];
    currSelects[stepToClear] = [];
    // formikProps && formikProps.setFieldValue(stepToClear,[]);
  }
  formikProps && formikProps.resetForm();
  // const stepToClear = currStep[currentStep - 1];
  // currSelects[stepToClear] = [];
  // formikProps && formikProps.setFieldValue(stepToClear,[]);
  setSelections(currSelects);
}


export const topThree = (productScores) => {
  return Object.entries(productScores).sort((a, b) => b[1] - a[1]).slice(0, 3);
}

