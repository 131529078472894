import React, { useContext } from "react";
import PropTypes from "prop-types";
import { AppContext } from "../../data/stores/app.store";


/**
 * A WizardHeader component renders the header. 
 */

const WizardHeader = (props) => {
  const { appData  } = useContext(AppContext);

    return (
      <h2 id={appData?.semanticList.stepHeader}>{props.header}</h2>
    );
}

WizardHeader.propTypes = {
  /** String of header */
  header: PropTypes.string,
}

export default WizardHeader;