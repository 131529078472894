import React from "react";
import PropTypes from "prop-types";

/**
 * A PFFlows component renders the current step in the flow. 
 *  - It takes in store and flows.
 */

const PFFlows = (props) => {
  return (
    <>
    {
      props.flows.map((flow) => {
          const ThisComponent = props.store.flow?.components[flow.name]?.component;
          const ComponentsProps = props.store.flow?.components[flow.name]?.props;
          if(ThisComponent && ComponentsProps){
            return (props.flow === flow.name && 
              <ThisComponent 
                key={flow.name} 
                store={props.store}
                {...flow} 
                {...props.props} 
                {...props.state} 
                {...ComponentsProps} />
            );
          }else{
            return(props.flow === flow.name && 
              <div key={flow.name}>Missing Flow Config</div>
            );
          }
        }
      )
    }
  </>
  );
}

PFFlows.propTypes = {
  /** Array of Wizard Flows */
  flows: PropTypes.array,
  /** Data Store of Wizard */
  store: PropTypes.object,
  /** Passed state handlers */
  state: PropTypes.object,
  /** Passed in Prop handlers */
  props: PropTypes.object
}

export default PFFlows;