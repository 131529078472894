import { assets } from "../assets/assets";

export const quizSelectionsPerStep = (data, step, gender) => {
  if(data.length > 0){
    const myQuizSteps = data;
    const thisStep = step - 1;
    const stepData = myQuizSteps[thisStep];

    const getOptionsPerGender = (gender) => 
      gender === 'unisex'? 
        stepData.configuration.answers:
          stepData.configuration.answers.filter(item => gender === item.configuration.gender || 'unisex' === item.configuration.gender);
    const genderSelections = getOptionsPerGender(gender);
    const selectionData = [];
    for (let i = 0; i < genderSelections.length; i++) {
      const genderConfig = genderSelections[i].configuration;
      const productScores = genderConfig.commands[0].configuration.products_score;
      const thisData = {
        name: genderConfig.text,
        image: genderConfig.image,
        productScores: productScores
      };
      selectionData.push(thisData);
    }
    const myQuizData = {
      name: stepData.configuration.text,
      category: stepData.configuration.category,
      image: stepData.configuration.image,
      info: stepData.configuration.info,
      max_answers: stepData.configuration.max_answers,
      min_answers: stepData.configuration.min_answers,
      multiple: stepData.configuration.multiple,
      answers: selectionData
    }
    return myQuizData;
  }
}

export const quizSelections = (data, gender) => {
  if(data.length > 0){
    const stepData = [];
    for (let i = 0; i < data.length; i++) {
      const getOptionsPerGender = (gender) => 
        gender === 'unisex'? 
          data[i].configuration.answers:
            data[i].configuration.answers.filter(item => gender === item.configuration.gender  || 'unisex' === item.configuration.gender);
      const genderSelections = getOptionsPerGender(gender);
      const selectionData = [];
        for (let i = 0; i < genderSelections.length; i++) {
        const genderConfig = genderSelections[i].configuration;
        const productScores = genderConfig.commands[0].configuration.products_score;
        const thisData = {
          name: genderConfig.text,
          images: genderConfig.images,
          productScores: productScores
        };
        selectionData.push(thisData);
      }

      const stepConfig = data[i].configuration;
      const thisData = {
        name: stepConfig.text,
        category: stepConfig.category,
        image: stepConfig.image,
        info: stepConfig.info,
        max_answers: stepConfig.max_answers,
        min_answers: stepConfig.min_answers,
        multiple: stepConfig.multiple,
        answers: selectionData
      };
      stepData.push(thisData);
    }

    return stepData;
  }
}

export const productData = (data,setProductScores,url,perfumeCat,productScores) => {
  if(perfumeCat){
    if (perfumeCat !== 0){
      const filtered = data.filter((element) => parseInt(element.categories) === perfumeCat);
      data = filtered;  
    } else {
      data = data;
    };
    const theseProductScores = {};
    if(data.length > 0){
      const productData = [];
      for (let i = 0; i < data.length; i++) {
        const product = data[i];
        const isScored = true; // all products in catalog scored by default
        if(isScored){
          const product100mlConfig = product?.variants?.filter(item => item?.size.toString().indexOf("100ML") > -1)[0];
          const thisData = {
            id: product.id,
            name: product.name,
            sku: product.sku,
            link: url + product.sku + "/",
            artist: product.secondarySubCopy,
            description:{
              short: product.descriptionShort,
              long: product.descriptionLong
            },
            variants: {
              ml100: {
                id: product100mlConfig?.variantId,
                category: product100mlConfig?.category,
                images: product100mlConfig?.imagesPDP.length > 0 ? product100mlConfig.imagesPDP: [assets.fmMissingProduct,assets.fmMissingPackaging],
                backupImage: product100mlConfig?.imagePDP,
                price: product100mlConfig?.price,
                sku: product100mlConfig?.sku
              }
            }
          };
          productData.push(thisData);
        }
        Object.keys(productScores).length === 0 && Object.assign(theseProductScores,{[data[i].sku]:0})
      }
      Object.keys(productScores).length === 0 && setProductScores(theseProductScores);  
      return productData;
    }
  }
}

export const perfumeCategories = (data) => {
  return data.filter(item => item.productApiId === "19566")[0].id;
}

export const introSelections = (data) => {
  if(data.questions){
    const splashData = data.questions[0];
    return splashData;
  }
}

export const wizardSelections = (data) => {
  if(data.questions){
    const myQuizSteps = data.questions.slice(1);
    return myQuizSteps;
  }
}

export const marketList = (data, markets) => { 
    const marketFilter = data.filter(item => markets.hasOwnProperty(item.code.toLowerCase()));
    return marketFilter;
}

export const selectionState = (content) => {
  const thisContent = content;
  let controlState = {};
  for (let i = 0; i < thisContent.length; i++) {
    Object.assign(controlState,{[thisContent[i].name]:{checked: false}});
    // Object.assign(thisContent[i],{checked:false});
  }
  return controlState;
}

export const setSelectionState = (selected, selections, state) => {
  const theseSelections = {...selections};
  theseSelections[selected].checked = state;
  return theseSelections;
}

export const selectionsAllState = (selections, state) => {
  const theseSelections = {...selections};
  for(let select in theseSelections){
    theseSelections[select].checked = state;
  }
  return theseSelections;
}