import React, { useState, useContext, useEffect } from "react";
import { Button } from "react-bootstrap";
import { PFContext, AppContext } from "../../data/stores/app.store";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import { trackButtonClick } from "../../analytics/PFSelectControls_ga";
import _ from "lodash";

/**
 * A PFSelectControls component renders the controls for each step. 
 *   - It renders next and previous buttons.
 */

const PFSelectControls = (props) => {
    const stepCount = props.steps && props.steps.length;
    const { productScores, wizardSelections, setProductScores, setWizardSelections} = useContext(PFContext);
    const [previousStep, setPreviousStep] = useState({});
    const [selectedItems, setSelectedItems] = useState(0);
    const states = { productScores, wizardSelections, setProductScores, previousStep, setPreviousStep, setWizardSelections };

    const theseMaxSelections = props.stepContent.max_answers;
    
    const { setFieldValue, values, resetForm } = useFormikContext() ?? {}; 
    /** Formik Context is optional -> Needed for documentation Engine - Docs do not need formik  */
    const formikProps = {setFieldValue, values, resetForm};
    const { appData  } = useContext(AppContext);

    useEffect(() => {
      const theseSelections = _.cloneDeep(wizardSelections);
      setSelectedItems(theseSelections[props?.stepContent?.category]?.length);
      return () => {
        // ** ALWAYS cleanup
        setSelectedItems(0);
      };
    }, [wizardSelections, selectedItems]);

    return (
      <div className="wizard-steps-control" id={appData?.semanticList.navigationWrapper}>
        <h3 className="flex-column" id={appData?.semanticList.stepTitle}>
          {props?.controls?.header} {props.currentStep} {props?.controls?.splitter} {stepCount}
          <span className="sub-header">{selectedItems}{props?.controls?.splitter}{theseMaxSelections}{props?.controls?.subHeader}</span>
        </h3>
        <ul>
          {props?.controls?.actions.map((item,i)=>{
            const buttonDirection = item.class[0].toUpperCase() + item.class.substring(1).toLowerCase();
            return (
              <li key={"pfControls" + i} className={item.class}>
                <Button 
                  onClick={(e) => {
                    item.action(e, props, states, formikProps, props.disableScrollTop);
                    trackButtonClick(selectedItems, props.header);
                    setSelectedItems(0);
                  }} 
                  id={appData?.semanticList.stepButton + buttonDirection} 
                  aria-label="ButtonDirection"
                  disabled={props.disabled}
                >
                  {item.icon}
                </Button>
              </li>
            );
          })}
        </ul>
      </div>
    );
}

PFSelectControls.propTypes = {
  steps: PropTypes.array,
  controls: PropTypes.shape({
    actions: PropTypes.array,
    header: PropTypes.string,
    splitter: PropTypes.string
  }),
  currentStep: PropTypes.number,
}

export default PFSelectControls;