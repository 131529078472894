import React, { useEffect } from "react";
import PropTypes from "prop-types";


/**
 * A ResponsiveGridImage component renders an image with different src based on screen breakpoint. 
 */

const ResponsiveGridImage = (props) => {
    useEffect(() => {
        return () => {
            // ** ALWAYS cleanup
        };
    }, [props]); // Dependency array, listen for changes to wizardProps

    return (
        <span className="img-wrapper">
            {Object.entries(props?.breakpoints).map((breakpoint) => {
                return (
                    <img key={breakpoint[1].imageSelector + "responsive-grid-select-image"} id={props.id} loading="lazy" className={breakpoint[1].classNames} src={props?.selectionData?.images[breakpoint[1].imageSelector]} alt={props?.selectionData?.name} />
                )
            })}
        </span>
    );
};

ResponsiveGridImage.propTypes = {
    selectionData: PropTypes.object,
    breakpoints: PropTypes.object,
}

export default ResponsiveGridImage;