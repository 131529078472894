import React from "react";
import PropTypes from "prop-types";

/**
 * A WizardFloatControls component *does something*. 
 */

const WizardFloatControls = (props) => {
    const Item = props.component;
    return (
      <div className="controls-wrapper">
        <Item {...props} />
      </div>
    );
}

WizardFloatControls.propTypes = {
  component: PropTypes.func,
  props: PropTypes.object,
}

export default WizardFloatControls;