import React, { useEffect, useState, useContext } from "react";
import { Form, Nav } from "react-bootstrap";
import Logo from "../UI/Logo";
import { AppContext } from "../../data/stores/app.store";
import { FetchData } from "../../data/FetchData";
import { marketStore } from "../../data/stores/PFStore";
import { timedCache } from "../../utils/dataUtils";
import { BrandStore } from "../../data/stores/BrandStore";

/**
 * A PFHeader component displays the website header. 
 *   - It renders language options, 
 *     header text, and the logo.
 */

export default function PFHeader(props) {
    const [languages, setLanguages] = useState([]);
    const [refreshData, setRefreshData] = useState(false);

    const { brandPrefix, appData, language, setLanguage, setAppName, setAppData, market } = useContext(AppContext);

    const getLanguages = appData.endpoints.languages() + "?language=" + language;
    // const getLanguages = "http://localhost:5002/api/languages?language=" + language;

    useEffect(() => {
        const addToCache = ['languages'];
        timedCache(24,addToCache,setRefreshData);
        
        const checkForLanguages = JSON.parse(localStorage.getItem('languages'));

        !checkForLanguages ?  
          FetchData(getLanguages, setLanguages, null, null, appData.languages,setRefreshData,'languages'):
            !refreshData && setLanguages(checkForLanguages);
        return () => {
            //cleanup
        };
    }, [appData]);

    const AppLogo = appData.appLogo;

    const handleLanguageChange = (e) => {
      // const checkForProducts = JSON.parse(localStorage.getItem('products'));
      localStorage.removeItem('products');
      localStorage.removeItem('quiz');
      localStorage.setItem('language', e.target.value);
      setLanguage(e.target.value);
      let marketLanguageStore;
      if (BrandStore?.euMarkets?.includes(market)) {
        marketLanguageStore = marketStore['eu'][e.target.value];
      } else {
        marketLanguageStore = e.target.value === 'en' ? marketStore[market]['en'] : marketStore[market][e.target.value];
      }
      
      if (marketLanguageStore && Object.keys(marketLanguageStore).length > 0) {
        setAppData(marketLanguageStore);
        setAppName(marketLanguageStore.appName);
      }
    }

    return (BrandStore &&
      <div className={brandPrefix + "-header-wrapper"}>
        <Nav className={brandPrefix + "-nav"}>
          <Nav.Item>
            <Form>
              <Form.Select value={language} onChange={(e)=>{return handleLanguageChange(e)}} aria-label="Languages" id={appData?.semanticList.languageSelection}>
                {
                  languages && languages.map((lang,i) => {
                    const availableLanguages = BrandStore?.markets[market]?.availableLanguages.indexOf(lang.id) > -1;
                    return availableLanguages && <option key={lang.id} value={lang.id}>{lang.name}</option>
                  })
                }
              </Form.Select>
            </Form>
          </Nav.Item>
        </Nav>
        <Logo type="vector" component={AppLogo} appPrefix={brandPrefix} id={appData?.semanticList.headerLogo} />
      </div>
    );
}
