export const trackPromotionClick = (props) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });  
    window.dataLayer.push({
      'event': 'eventload',
      'eventCategory': 'ecommerce',
      'eventAction': 'promotionClick',
      'eventLabel': 'click',
      'ecommerce': {
        'promoClick': {
          'promotions': [{
            'id': props.id,
            'name': props.actionText,
            'creative': props.header,
            // 'position': '{{promoPosition}}'
          }]
        }
      }
    });
  };