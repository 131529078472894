import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate } from "react-router-dom";
import './scss/elc-styles.scss';
import PerfumeFinder from './components/Brand/PerfumeFinder';
import { AppContext, NavigateContext } from '../src/data/stores/app.store';
import { marketStore } from './data/stores/PFStore';
import { BrandStore } from './data/stores/BrandStore';
import { useSearchParams } from 'react-router-dom';
import PageNotFound from './components/UI/PageNotFound';
// import { refMarket } from './utils/market';
import { determineMarket } from './utils/market';
// import { getLanguageFromLocale } from './utils/languages';

function App() {
  const [brand, setBrand] = useState(''); // *- We Set our states
  const [brandPrefix, setBrandPrefix] = useState(''); // *- We Set our states
  const [language, setLanguage] = useState(''); // *- We Set our states
  const [market, setMarket] = useState(''); // *- We Set our states
  const [appData, setAppData] = useState({}); // *- We Set our states
  const [appName, setAppName] = useState(''); // *- We Set our states
  const [deviceLocation, setDeviceLocation] = useState('web'); // *- We Set our states

  const [searchParams] = useSearchParams();

  // const reload = () => window.location.reload();

  useEffect(() => {
    // *- We load our stuff
    // window.location.pathname.indexOf('docs') > -1 && reload(); 
    setBrand(BrandStore.name);
    const thisDeviceLocation = searchParams.get("pos") ?? 'web';
    setDeviceLocation(thisDeviceLocation);
    const thisMarket = determineMarket(searchParams);
    setMarket(thisMarket);
    if(market && market !== ''){
      const checkForLanguage = localStorage.getItem('language');
        localStorage.getItem('language')?setLanguage(checkForLanguage) : 
      setLanguage(BrandStore?.markets[market]?.defaultLanguage);
      setBrandPrefix(BrandStore.prefix);
      const thisLanguage = localStorage.getItem('language')?localStorage.getItem('language'): (BrandStore && BrandStore?.markets[market]?.defaultLanguage);
      let marketLanguageStore;
      if (BrandStore?.euMarkets?.includes(thisMarket)) {
        marketLanguageStore = marketStore['eu'][thisLanguage];
      } else {
        marketLanguageStore = thisLanguage === 'en' ? marketStore[thisMarket]['en'] : marketStore[thisMarket][thisLanguage];
      }
      if (marketLanguageStore && Object.keys(marketLanguageStore).length > 0) {
        setAppData(marketLanguageStore);
        setAppName(marketLanguageStore.appName);
      }

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        country_code: market,
        site_language: thisLanguage,
        brand: BrandStore.name,
        brandId: BrandStore.prefix,
        pageType: marketLanguageStore.appName
      });

      window.dataLayer.push({
        'event': 'page_load',
        'page_name': appName, 
      });

    }
    return () => {
      // *- We cleanup
      setBrand('');
      setLanguage('');
      setMarket('');
      setAppData({});
      setAppName('');
      setBrandPrefix('');
    };
  },[market]); // Danger: Application Level Dependency array, use with **EXTREME** caution

  const navigate = useNavigate();

  const api_regex = /^\/nodeAPI\/.*/
   if (api_regex.test(window.location.pathname)) {
      return <div />
   }else{
    return (
      <NavigateContext.Provider value={navigate}>
          <AppContext.Provider
              value={{
                language: language,
                setLanguage: setLanguage,
                market:market, 
                setMarket: setMarket,
                appData: appData,
                setAppData: setAppData,
                appName: appName,
                setAppName: setAppName,
                brand: brand, 
                setBrand: setBrand,
                brandPrefix: brandPrefix,
                deviceLocation: deviceLocation, 
                setDeviceLocation: setDeviceLocation
              }}
          >
            <Routes>
              <Route path="/" element={Object.keys(appData).length > 0 && <PerfumeFinder store={appData} />} /> 
              {/* <Route path="/docs" element={<Navigate to="/docs/index.html" replace={true} />} forceRefresh={true}  /> */}
              <Route path="*" element={Object.keys(appData).length > 0 && <PageNotFound data={appData.pageNotFound} store={appData}/>} />
            </Routes>
          </AppContext.Provider>
      </NavigateContext.Provider>
    );
  }
}

export default App;
