import React, { useContext } from "react";
import PropTypes from "prop-types";
import { AppContext } from "../../data/stores/app.store";
/**
 * A WizardLandingContent component displays landing page content for a WizardLanding component. 
 *   - It takes in 2 components. 
 *   - It displays a question header relevant to the selection.
 *   - It renders a selection and a disclaimer component.
*/

const WizardLandingContent = (props) => {
  const { appData  } = useContext(AppContext);

    return (
      <div className="wizard-landing-content"> 
        <h3 id={appData?.semanticList.miniHeader}>
          {props.question.title}
        </h3>
        {props.components?.disclaimer && <props.components.disclaimer {...props} />}
        {props.components?.selection && <props.components.selection {...props} />}
      </div>
    );
}

WizardLandingContent.propTypes = {
  components: PropTypes.shape({
    selection: PropTypes.func,
    disclaimer: PropTypes.func
  }),
  question: PropTypes.shape({
    title: PropTypes.string,
  }),
}

export default WizardLandingContent;