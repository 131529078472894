import React from "react";
import Icon from "../UI/Icon";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";

/**
 * A PFConfirmation component renders confirmation message. 
 *   - It displays a checkmark Icon component.
 *   - It displays a tagline below.
 */

const PFConfirmation = (props) => {
    return (
        <Row>
            <Col>
                <Row className="text-center fs-2 my-3">
                    <Icon variant="check"></Icon>
                </Row>
                <Row className="text-center fs-5">
                    {props.confirmationTagline}
                </Row>
            </Col>
        </Row>
    );
}


PFConfirmation.propTypes = {
    confirmationTagline: PropTypes.string,
}

export default PFConfirmation;
