import React, { useEffect, useState, useContext } from "react";
import { AppContext, PFContext } from "../../data/stores/app.store";
import { BrandStore } from "../../data/stores/BrandStore";
import PropTypes from "prop-types";
import PFFlows from "./PFFlows";
import { FetchData } from "../../data/FetchData";
import { introSelections, wizardSelections, productData, perfumeCategories, marketList} from "../../transformers/fmTransformers";
import { timedCache } from "../../utils/dataUtils";

const PerfumeFinderWizard = (props) => {
    const [score, setScore] = useState(0);
    const [gender, setGender] = useState('no gender selected');
    // const [name, setName] = useState(0);
    const [privacyAgree, setPrivacyAgree] = useState(false);
    const [steps, setSteps] = useState([]);
    const [currentStep, setCurrentStep] = useState(1);
    const [quizData, setQuizData] = useState([]);
    const [products, setProducts] = useState([]);
    const [apiProducts, setApiProducts] = useState([]);
    const [markets, setMarkets] = useState([]);
    const [perfumeCat, setPerfumeCat] = useState(0);
    const [newQuizData, setNewQuizData] = useState([]);

    const { appData, market, setMarket, language } = useContext(AppContext);
    const { productScores, setProductScores } = useContext(PFContext);

    const whichProductUrl = (market === "fr" || market === "us" || market === "uk") ? 
      appData.marketConfig[market].productsURL:
      appData.marketConfig['eu'].productsURL;

    const getQuizData = appData.endpoints.thisQuiz('perfume_finder');
    const getProductCats = appData.endpoints.productCats();
    const getProducts = appData.endpoints.marketProducts(market);
    const getMarkets = appData.endpoints.markets();
    const handleQuizData = (data) => wizardSelections(data);
    const handlePerfumes = (data) => perfumeCategories(data);
    const handleMarketData = (data) => marketList(data, BrandStore?.markets);
    const handleProductData = (data) => productData(data,props.setProductScores,whichProductUrl, perfumeCat, productScores);
  
    const [refreshData, setRefreshData] = useState(false);

    const thisState = {
      score, 
      setScore, 
      steps, 
      setSteps,
      currentStep, 
      setCurrentStep, 
      gender, 
      setGender,
      privacyAgree, 
      setPrivacyAgree,
      market, 
      setMarket,
      markets,
      refreshData
    };

    const handleCacheSetProducts = (checkForProducts,flow) => {
      setProducts(checkForProducts)
      if(checkForProducts && flow === "intro"){
        const theseProductScores = {};
        for (let i = 0; i < checkForProducts.length; i++) {
          Object.assign(theseProductScores,{[checkForProducts[i].sku]:0})
        }
        setProductScores(theseProductScores);
      }
    }

    useEffect(() => {
      if(Object.keys(appData).length > 0){
        const addToCache = ['d_expires','quiz','markets','categories','products'];
        timedCache(8,addToCache,setRefreshData);

        const checkForQuizData = JSON.parse(localStorage.getItem('quiz'));
        const checkForProductCats = JSON.parse(localStorage.getItem('categories'));
        const checkForMarkets = JSON.parse(localStorage.getItem('markets'));
        const checkForProducts = JSON.parse(localStorage.getItem('products'));
        
        !checkForQuizData ? 
          FetchData(getQuizData, setQuizData, handleQuizData, setNewQuizData,[], setRefreshData, "quiz", true, language):
            setNewQuizData(checkForQuizData);
        !checkForProductCats ? 
          FetchData(getProductCats, null, handlePerfumes, setPerfumeCat,[], setRefreshData, "categories",true, language):
            setPerfumeCat(checkForProductCats);
        !checkForMarkets ? 
          FetchData(getMarkets, null, handleMarketData, setMarkets,[], setRefreshData, "markets", true, language):
            setMarkets(checkForMarkets);
        perfumeCat && (!checkForProducts ? 
            FetchData(getProducts, setApiProducts, handleProductData, setProducts,[], setRefreshData, "products", true, language):
              handleCacheSetProducts(checkForProducts,props.flow));
      }
      return () => {
        // ** ALWAYS cleanup
      };
    },[language, perfumeCat]); // Dependency array, listen for changes to wizardProps

    const wizardProps = {
      introData: introSelections(quizData),
      wizardData: newQuizData,
      products: products,
      apiProducts: apiProducts
    };

    return (
      quizData && <PFFlows store={props.store} flows={props.store.flows} flow={props.flow} state={thisState} props={wizardProps} />
    );
}

PerfumeFinderWizard.propTypes = {
  /** Array of Wizard Flows */
  flow: PropTypes.string,
  /** Data Store of Wizard */
  store: PropTypes.object,
}

export default PerfumeFinderWizard;