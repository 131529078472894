import PropTypes from "prop-types";
import React from "react";
import WizardHeader from "./WizardHeader";
import WizardGroup from "./WizardGroup";
import WizardFloatControls from "./WizardFloatControls";

/**
 * A scored wizard component is a wizard that tracks a score based on selections. 
 *   - It takes in a header, a selection and a control component. 
 */

const ScoredWizard = (props) => {
    return (
      <div className="wizard-wrapper">
        <WizardHeader {...props} />
        <WizardGroup component={props.components.selections} group={props.components.group} {...props} />
        <WizardFloatControls component={props.components.controls} {...props} />
      </div>
    );
}

ScoredWizard.propTypes = {
  components: PropTypes.object
}

export default ScoredWizard;