import React, { useEffect, useState, useContext } from "react";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { PFContext } from "../../data/stores/app.store";
import PropTypes from "prop-types";
import _ from "lodash";

/**
 * A PFSelections component renders Perfume Finder Quiz Selection per step.
 *   
 */

const PFSelections = (props) => {
    const Wizard = props.components.wizard;
    const [pfWizardData, setPFWizardData] = useState([]);
    const [stepContent, setStepContent] = useState([]);

    const {wizardData,currentStep,gender} = props;

    const { setFlow, setBGColor, bgColor } = useContext(PFContext);

    useEffect(() => {
      // componentDidMount - On initial load
      // get and set your wizrd props here
      const thisWizardData = props.getPFData && props.getPFData(wizardData,gender);
      wizardData && setPFWizardData(thisWizardData);
      props.setSteps && props.setSteps(thisWizardData);
      const thisStepData = thisWizardData?thisWizardData[currentStep -1]:[];
      setStepContent(thisStepData);
      props.stepsData && setBGColor(props.stepsData[currentStep - 1].bg);
      return () => {
        // ** ALWAYS cleanup
      };
    },[currentStep, bgColor, wizardData]); // Dependency array, listen for changes to wizardProps

    const init = _.cloneDeep(props.store.perfumeFinderFields);
    // debugger;

    return (
      <Formik
        initialValues={{...init}}
        onSubmit={(values) => {
          //Gather and track Selections
        }}
        validationSchema={props.validation?.perfumeFinderSchema}
      >
        {(formikProps) => {
          return(
            <Form>
              <Wizard {...props} setFlow={setFlow} header={stepContent.name} steps={pfWizardData} stepContent={stepContent} />
            </Form>
          );
        }}
      </Formik>
    );
}

PFSelections.propTypes = {
  components: PropTypes.object,
  perfumeFinderFields: PropTypes.shape({
    accessories: PropTypes.array,
    enviroment: PropTypes.array,
    footwear: PropTypes.array,
    hair: PropTypes.array,
    looks: PropTypes.array,
    transport: PropTypes.array,
    underwear: PropTypes.array,
  }),
  getPFData: PropTypes.func,
  steps: PropTypes.array,
  validation: PropTypes.object
}

export default PFSelections;