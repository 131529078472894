import React, {useEffect, useState} from "react";

const TextExpand = (props) => {

    const [expanded, setExpanded] = useState(false);
    const [text, setText] = useState(false);

    const textLength = typeof props.copy === "string" && props.copy.length;

    useEffect(() => {
        (textLength > props.charCount) 
          && setText(props.copy.substring(0, props.charCount))
          && setExpanded(false);
        return () => {
          setText('');
        };
    }, [props.copy]);

    return (textLength > props.charCount ?
      <p className={props.className}>
        {text} <a 
          className="d-block mt-2 text-center"
          href="#" 
          onClick={(e)=>{
            e.preventDefault();
            return !expanded ? 
              (setExpanded(true),setText(props.copy)):
              (setExpanded(false),setText(props.copy.substring(0, props.charCount)))
            }}>{expanded ? "Show Less": "Show More"}</a>
      </p>:
      <p className={props.className}>
        {props.copy}
      </p>
    );
}

export default TextExpand;