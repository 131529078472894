import React, { useEffect, useContext } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { AppContext } from "../../data/stores/app.store";

/**
 * Footer for the Frederic Malle Perfume Finder
 */

export default function PFFooter(props) {
    const { brandPrefix, appData, language, setLanguage, setAppName, setAppData, market } = useContext(AppContext);

    return (props?.store?.footer &&
      <div className="fm-container container">
        <Navbar expand="lg" collapseOnSelect>
          <Nav className={brandPrefix + "-footer-nav"}>
            {
              props.store && props.store.footer.links.map((link, index) =>
                < Nav.Item key={"pfFooter-" + index} className={link.parentClass}>
                  <Nav.Link href={link.url} target={link.target} className={link.class}>{link.title}</Nav.Link>
                </Nav.Item>
              )
            }
          </Nav>
        </Navbar>
      </div>
    );
}