import React from "react";
import WizardLanding from "../UI/WizardLanding";
import PropTypes from "prop-types";

/**
 * A PFSplash component *does something*. 
 */

const PFSplash = (props) => {
    return (
      <WizardLanding {...props} />
    );
}

PFSplash.propTypes = {
  props: PropTypes.object
}

export default PFSplash;