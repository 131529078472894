import React, { useEffect, useContext} from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import Button from "react-bootstrap/Button";
import PropTypes from "prop-types";
import TextExpand from "../UI/TextExpand";
import { AppContext } from "../../data/stores/app.store";
import { trackProductClick } from "../../analytics/PFPerfumeReccs_ga";

import "swiper/scss";
import "swiper/scss/navigation";
import { Container } from "react-bootstrap";

/**
 * A PFPerfumeReccs displays the top three perfumes on the results page. 
 *   - It displays a swiper that contains: the perfume image, 
 *    the box image on hover, the perfume title, the perfume artist, 
 *    the description, and the button.
 */

const PFPerfumeReccs = (props) => {
  const {appData} = useContext(AppContext);

  useEffect(() => {
    return () => {
      // ** ALWAYS cleanup
    };
  }, [props.productReccs]);

  return (
    <Container>
      <div className="perfume-recs">
        <h3 className="perfume-recs-header" id={appData?.semanticList.suggestionsHeader}>{props.section.title}</h3>
        <div className="promo">
          <Swiper
            initialSlide = {0}
            slidesPerView={1}
            spaceBetween={0}
            pagination={true}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
            modules={[Navigation]}
            breakpoints={props.breakpoints? props.breakpoints:{
              768: {
                slidesPerView: 2,
                spaceBetween: 1,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 1,
              },
            }}
          >
            {props.productReccs && props.productReccs.map((product, i) => {
              const targetProduct = product?.variants[props.displayVariant];
              return (
                <SwiperSlide key={"productReccomendation" + i} >
                  <div className="perfume-recs-top">
                    <img src={targetProduct.images[0]} alt={product.name} className="perfume-recs-img" id={appData?.semanticList.suggestionsImage + product.name}  />
                    <img src={targetProduct.images[1]} alt={product.name + "packaging"} className="perfume-recs-hover-img" />
                  </div>
                  <div className="perfume-recs-bottom">
                    <div className="perfume-recs-text">
                      <h3 className="perfume-title" id={appData?.semanticList.suggestionsTitle + i}>{product.name}</h3>
                      <h4 className="perfume-artist">{product.artist}</h4>
                      <div className="perfume-description" id={appData?.semanticList.suggestionsID + i}>
                        <TextExpand copy={product.description?.long} charCount={90} />
                      </div>
                    </div>
                  </div>
                  <div className="perfume-recs-button">
                    <div className="button-inner">
                      <Button
                        as="a"
                        target="_blank"
                        id={appData?.semanticList.discoverButton + i}
                        href={product.link}
                        className="perfume-recs-btn"
                        onClick={() => trackProductClick(product, i)}
                        >{props.section.buttonText}</Button>
                    </div>
                  </div>
                </SwiperSlide>
              )
            }
            )}
          </Swiper>
          <i className="swiper-button-next"></i>
          <i className="swiper-button-prev"></i>
        </div>
      </div>
    </Container>
  );
};

PFPerfumeReccs.propTypes = {
  /** Passed in header */
  header: PropTypes.string,
  /** Passed in product data */
  productsData: PropTypes.array,
  /** Passed in breakpoints */
  breakpoints: PropTypes.object

  
}

export default PFPerfumeReccs;