import { PerfumeFinderConfigFR, PerfumeFinderConfigUK, PerfumeFinderConfigUS, marketConfigs, PerfumeFinderConfig } from "../configs/PerfumeFinderConfig"
import { yupSchemas } from "../../utils/validationUtils"

export const marketStore = {
  us: {
    en: {
      ...PerfumeFinderConfigUS['en'],
      marketConfig: marketConfigs,
      header: {
        action: {
          type: "dropdown",
          items: PerfumeFinderConfig['en'].languages
        }
      },
      flows: [
        {
          name: "intro",
          header: {
            lead: "Find the perfume that matches",
            trail: "your style"
          },
          selections: {
            options: [
              {
                label: "Man",
                value: "man"
              },
              {
                label: "Woman",
                value: "woman"
              },
              {
                label: "It doesn't matter",
                value: "unisex"
              }
            ]
          },
          disclaimer: {
            label: PerfumeFinderConfigUS['en'].privacyDisclaimer,
            control: {
              type: "checkbox",
              value: true,
            }
          },
          question: {
            title: "Who are you?",
          },
          components: PerfumeFinderConfigUS['en']?.flow?.components?.intro?.subComponents,
        },
        {
          name: "wizard",
          stepsData: [
            {
              name: "Looks",
              htmlName: "looks",
              bg: 'bg-black'
            },
            {
              name: "Hair Styles",
              htmlName: "hair",
              bg: 'bg-red'
            },
            {
              name: "Footwear",
              htmlName: "footwear",
              bg: 'bg-black'
            },
            {
              name: "Transportation",
              htmlName: "transport",
              bg: 'bg-red'
            },
            {
              name: "Underwear",
              htmlName: "underwear",
              bg: 'bg-black'
            },
            {
              name: "Accessories",
              htmlName: "accessories",
              bg: 'bg-red'
            },
            {
              name: "Environment",
              htmlName: "environment",
              bg: 'bg-black'
            },
          ],
          validation: yupSchemas ?? {},
          components: PerfumeFinderConfigUS['en']?.flow?.components?.wizard?.subComponents,
          controls: {
            header: PerfumeFinderConfigUS['en']?.flow?.components?.wizard.controlsHeading,
            splitter: PerfumeFinderConfigUS['en']?.flow?.components?.wizard.controlsDivider,
            subHeader: PerfumeFinderConfigUS['en']?.flow?.components?.wizard.controlsSubHeaderCopy,
            actions: [
              {
                name: "Previous",
                class: "prev-btn",
                icon: PerfumeFinderConfigUS['en']?.flow?.components?.wizard?.controlsIcons.prev,
                action: PerfumeFinderConfigUS['en']?.flow?.components?.wizard?.props?.previousStep
              },
              {
                name: "Next",
                class: "next-btn",
                icon: PerfumeFinderConfigUS['en']?.flow?.components?.wizard?.controlsIcons.next,
                action: PerfumeFinderConfigUS['en']?.flow?.components?.wizard?.props?.nextStep
              }
            ]
          }
        },
        {
          name: "results",
          sections: {
            ...PerfumeFinderConfigUS['en'].flow.components.results.sections
          },
          emailFields: {
            email: "",
            opt_in: true,
          },
          validation: yupSchemas,
          components: PerfumeFinderConfigUS['en']?.flow?.components?.results?.subComponents,
        }
      ],
    }
  },
  uk: {
    en: {
      ...PerfumeFinderConfigUK['en'],
      marketConfig: marketConfigs,
      header: {
        action: {
          type: "dropdown",
          items: PerfumeFinderConfig['en'].languages
        }
      },
      flows: [
        {
          name: "intro",
          header: {
            lead: "Find the perfume that matches",
            trail: "your style"
          },
          selections: {
            options: [
              {
                label: "Man",
                value: "man"
              },
              {
                label: "Woman",
                value: "woman"
              },
              {
                label: "It doesn't matter",
                value: "unisex"
              }
            ]
          },
          disclaimer: {
            label: PerfumeFinderConfigUK['en'].privacyDisclaimer,
            control: {
              type: "checkbox",
              value: true,
            }
          },
          question: {
            title: "Who are you?",
          },
          components: PerfumeFinderConfigUK['en']?.flow?.components?.intro?.subComponents,
        },
        {
          name: "wizard",
          stepsData: [
            {
              name: "Looks",
              htmlName: "looks",
              bg: 'bg-black'
            },
            {
              name: "Hair Styles",
              htmlName: "hair",
              bg: 'bg-red'
            },
            {
              name: "Footwear",
              htmlName: "footwear",
              bg: 'bg-black'
            },
            {
              name: "Transportation",
              htmlName: "transport",
              bg: 'bg-red'
            },
            {
              name: "Underwear",
              htmlName: "underwear",
              bg: 'bg-black'
            },
            {
              name: "Accessories",
              htmlName: "accessories",
              bg: 'bg-red'
            },
            {
              name: "Environment",
              htmlName: "environment",
              bg: 'bg-black'
            },
          ],
          validation: yupSchemas ?? {},
          components: PerfumeFinderConfigUK['en']?.flow?.components?.wizard?.subComponents,
          controls: {
            header: PerfumeFinderConfigUK['en']?.flow?.components?.wizard.controlsHeading,
            splitter: PerfumeFinderConfigUK['en']?.flow?.components?.wizard.controlsDivider,
            subHeader: PerfumeFinderConfigUK['en']?.flow?.components?.wizard.controlsSubHeaderCopy,
            actions: [
              {
                name: "Previous",
                class: "prev-btn",
                icon: PerfumeFinderConfigUK['en']?.flow?.components?.wizard?.controlsIcons.prev,
                action: PerfumeFinderConfigUK['en']?.flow?.components?.wizard?.props?.previousStep
              },
              {
                name: "Next",
                class: "next-btn",
                icon: PerfumeFinderConfigUK['en']?.flow?.components?.wizard?.controlsIcons.next,
                action: PerfumeFinderConfigUK['en']?.flow?.components?.wizard?.props?.nextStep
              }
            ]
          }
        },
        {
          name: "results",
          sections: {
            ...PerfumeFinderConfigUK['en'].flow.components.results.sections
          },
          emailFields: {
            email: "",
            opt_in: true,
          },
          validation: yupSchemas,
          components: PerfumeFinderConfigUK['en']?.flow?.components?.results?.subComponents,
        }
      ],

    }
  },
  eu: {
    en: {
      ...PerfumeFinderConfigFR['en'],
      marketConfig: marketConfigs,
      header: {
        action: {
          type: "dropdown",
          items: PerfumeFinderConfig['en'].languages
        }
      },
      flows: [
        {
          name: "intro",
          header: {
            lead: "Find the perfume that matches",
            trail: "your style"
          },
          selections: {
            options: [
              {
                label: "Man",
                value: "man"
              },
              {
                label: "Woman",
                value: "woman"
              },
              {
                label: "It doesn't matter",
                value: "unisex"
              }
            ]
          },
          disclaimer: {
            label: PerfumeFinderConfigFR['en'].privacyDisclaimer,
            control: {
              type: "checkbox",
              value: true,
            }
          },
          question: {
            title: "Who are you?",
          },
          components: PerfumeFinderConfigFR['en']?.flow?.components?.intro?.subComponents,
        },
        {
          name: "wizard",
          stepsData: [
            {
              name: "Looks",
              htmlName: "looks",
              bg: 'bg-black'
            },
            {
              name: "Hair Styles",
              htmlName: "hair",
              bg: 'bg-red'
            },
            {
              name: "Footwear",
              htmlName: "footwear",
              bg: 'bg-black'
            },
            {
              name: "Transportation",
              htmlName: "transport",
              bg: 'bg-red'
            },
            {
              name: "Underwear",
              htmlName: "underwear",
              bg: 'bg-black'
            },
            {
              name: "Accessories",
              htmlName: "accessories",
              bg: 'bg-red'
            },
            {
              name: "Environment",
              htmlName: "environment",
              bg: 'bg-black'
            },
          ],
          validation: yupSchemas ?? {},
          components: PerfumeFinderConfigFR['en']?.flow?.components?.wizard?.subComponents,
          controls: {
            header: PerfumeFinderConfigFR['en']?.flow?.components?.wizard.controlsHeading,
            splitter: PerfumeFinderConfigFR['en']?.flow?.components?.wizard.controlsDivider,
            subHeader: PerfumeFinderConfigFR['en']?.flow?.components?.wizard.controlsSubHeaderCopy,
            actions: [
              {
                name: "Previous",
                class: "prev-btn",
                icon: PerfumeFinderConfigFR['en']?.flow?.components?.wizard?.controlsIcons.prev,
                action: PerfumeFinderConfigFR['en']?.flow?.components?.wizard?.props?.previousStep
              },
              {
                name: "Next",
                class: "next-btn",
                icon: PerfumeFinderConfigFR['en']?.flow?.components?.wizard?.controlsIcons.next,
                action: PerfumeFinderConfigFR['en']?.flow?.components?.wizard?.props?.nextStep
              }
            ]
          }
        },
        {
          name: "results",
          sections: {
            ...PerfumeFinderConfigFR['en'].flow.components.results.sections
          },
          emailFields: {
            email: "",
            opt_in: true,
          },
          validation: yupSchemas,
          components: PerfumeFinderConfigFR['en']?.flow?.components?.results?.subComponents,
        }
      ],

    },
    fr: {
      ...PerfumeFinderConfigFR['fr'],
      marketConfig: marketConfigs,
      header: {
        action: {
          type: "dropdown",
          items: PerfumeFinderConfig['fr'].languages
        }
      },
      flows: [
        {
          name: "intro",
          header: {
            lead: "Trouvez le parfum qui vous ressemble",
            trail: ""
          },
          selections: {
            options: [
              {
                label: "Homme",
                value: "man"
              },
              {
                label: "Femme",
                value: "woman"
              },
              {
                label: "Peu Importe",
                value: "unisex"
              }
            ]
          },
          disclaimer: {
            label: PerfumeFinderConfigFR['fr'].privacyDisclaimer,
            control: {
              type: "checkbox",
              value: true,
            }
          },
          question: {
            title: "Qui êtes-vous?",
          },
          components: PerfumeFinderConfigFR['fr']?.flow?.components?.intro?.subComponents,
        },
        {
          name: "wizard",
          stepsData: [
            {
              name: "Looks",
              htmlName: "looks",
              bg: 'bg-black'
            },
            {
              name: "Hair Styles",
              htmlName: "hair",
              bg: 'bg-red'
            },
            {
              name: "Footwear",
              htmlName: "footwear",
              bg: 'bg-black'
            },
            {
              name: "Transportation",
              htmlName: "transport",
              bg: 'bg-red'
            },
            {
              name: "Underwear",
              htmlName: "underwear",
              bg: 'bg-black'
            },
            {
              name: "Accessories",
              htmlName: "accessories",
              bg: 'bg-red'
            },
            {
              name: "Environment",
              htmlName: "environment",
              bg: 'bg-black'
            },
          ],
          validation: yupSchemas ?? {},
          components: PerfumeFinderConfigFR['fr']?.flow?.components?.wizard?.subComponents,
          controls: {
            header: PerfumeFinderConfigFR['fr']?.flow?.components?.wizard.controlsHeading,
            splitter: PerfumeFinderConfigFR['fr']?.flow?.components?.wizard.controlsDivider,
            subHeader: PerfumeFinderConfigFR['fr']?.flow?.components?.wizard.controlsSubHeaderCopy,
            actions: [
              {
                name: "Previous",
                class: "prev-btn",
                icon: PerfumeFinderConfigFR['fr']?.flow?.components?.wizard?.controlsIcons.prev,
                action: PerfumeFinderConfigFR['fr']?.flow?.components?.wizard?.props?.previousStep
              },
              {
                name: "Next",
                class: "next-btn",
                icon: PerfumeFinderConfigFR['fr']?.flow?.components?.wizard?.controlsIcons.next,
                action: PerfumeFinderConfigFR['fr']?.flow?.components?.wizard?.props?.nextStep
              }
            ]
          }
        },
        {
          name: "results",
          sections: {
            ...PerfumeFinderConfigFR['fr'].flow.components.results.sections
          },
          emailFields: {
            email: "",
            opt_in: true,
          },
          validation: yupSchemas,
          components: PerfumeFinderConfigFR['fr']?.flow?.components?.results?.subComponents,
        }
      ],
    },
  },
  fr: {
    en: {
      ...PerfumeFinderConfigFR['en'],
      marketConfig: marketConfigs,
      header: {
        action: {
          type: "dropdown",
          items: PerfumeFinderConfig['en'].languages
        }
      },
      flows: [
        {
          name: "intro",
          header: {
            lead: "Find the perfume that matches",
            trail: "your style"
          },
          selections: {
            options: [
              {
                label: "Man",
                value: "man"
              },
              {
                label: "Woman",
                value: "woman"
              },
              {
                label: "It doesn't matter",
                value: "unisex"
              }
            ]
          },
          disclaimer: {
            label: PerfumeFinderConfigFR['en'].privacyDisclaimer,
            control: {
              type: "checkbox",
              value: true,
            }
          },
          question: {
            title: "Who are you?",
          },
          components: PerfumeFinderConfigFR['en']?.flow?.components?.intro?.subComponents,
        },
        {
          name: "wizard",
          stepsData: [
            {
              name: "Looks",
              htmlName: "looks",
              bg: 'bg-black'
            },
            {
              name: "Hair Styles",
              htmlName: "hair",
              bg: 'bg-red'
            },
            {
              name: "Footwear",
              htmlName: "footwear",
              bg: 'bg-black'
            },
            {
              name: "Transportation",
              htmlName: "transport",
              bg: 'bg-red'
            },
            {
              name: "Underwear",
              htmlName: "underwear",
              bg: 'bg-black'
            },
            {
              name: "Accessories",
              htmlName: "accessories",
              bg: 'bg-red'
            },
            {
              name: "Environment",
              htmlName: "environment",
              bg: 'bg-black'
            },
          ],
          validation: yupSchemas ?? {},
          components: PerfumeFinderConfigFR['en']?.flow?.components?.wizard?.subComponents,
          controls: {
            header: PerfumeFinderConfigFR['en']?.flow?.components?.wizard.controlsHeading,
            splitter: PerfumeFinderConfigFR['en']?.flow?.components?.wizard.controlsDivider,
            subHeader: PerfumeFinderConfigFR['en']?.flow?.components?.wizard.controlsSubHeaderCopy,
            actions: [
              {
                name: "Previous",
                class: "prev-btn",
                icon: PerfumeFinderConfigFR['en']?.flow?.components?.wizard?.controlsIcons.prev,
                action: PerfumeFinderConfigFR['en']?.flow?.components?.wizard?.props?.previousStep
              },
              {
                name: "Next",
                class: "next-btn",
                icon: PerfumeFinderConfigFR['en']?.flow?.components?.wizard?.controlsIcons.next,
                action: PerfumeFinderConfigFR['en']?.flow?.components?.wizard?.props?.nextStep
              }
            ]
          }
        },
        {
          name: "results",
          sections: {
            ...PerfumeFinderConfigFR['en'].flow.components.results.sections
          },
          emailFields: {
            email: "",
            opt_in: true,
          },
          validation: yupSchemas,
          components: PerfumeFinderConfigFR['en']?.flow?.components?.results?.subComponents,
        }
      ],

    },
    fr: {
      ...PerfumeFinderConfigFR['fr'],
      marketConfig: marketConfigs,
      header: {
        action: {
          type: "dropdown",
          items: PerfumeFinderConfig['fr'].languages
        }
      },
      flows: [
        {
          name: "intro",
          header: {
            lead: "Trouvez le parfum qui vous ressemble",
            trail: ""
          },
          selections: {
            options: [
              {
                label: "Homme",
                value: "man"
              },
              {
                label: "Femme",
                value: "woman"
              },
              {
                label: "Peu Importe",
                value: "unisex"
              }
            ]
          },
          disclaimer: {
            label: PerfumeFinderConfigFR['fr'].privacyDisclaimer,
            control: {
              type: "checkbox",
              value: true,
            }
          },
          question: {
            title: "Qui êtes-vous?",
          },
          components: PerfumeFinderConfigFR['fr']?.flow?.components?.intro?.subComponents,
        },
        {
          name: "wizard",
          stepsData: [
            {
              name: "Looks",
              htmlName: "looks",
              bg: 'bg-black'
            },
            {
              name: "Hair Styles",
              htmlName: "hair",
              bg: 'bg-red'
            },
            {
              name: "Footwear",
              htmlName: "footwear",
              bg: 'bg-black'
            },
            {
              name: "Transportation",
              htmlName: "transport",
              bg: 'bg-red'
            },
            {
              name: "Underwear",
              htmlName: "underwear",
              bg: 'bg-black'
            },
            {
              name: "Accessories",
              htmlName: "accessories",
              bg: 'bg-red'
            },
            {
              name: "Environment",
              htmlName: "environment",
              bg: 'bg-black'
            },
          ],
          validation: yupSchemas ?? {},
          components: PerfumeFinderConfigFR['fr']?.flow?.components?.wizard?.subComponents,
          controls: {
            header: PerfumeFinderConfigFR['fr']?.flow?.components?.wizard.controlsHeading,
            splitter: PerfumeFinderConfigFR['fr']?.flow?.components?.wizard.controlsDivider,
            subHeader: PerfumeFinderConfigFR['fr']?.flow?.components?.wizard.controlsSubHeaderCopy,
            actions: [
              {
                name: "Previous",
                class: "prev-btn",
                icon: PerfumeFinderConfigFR['fr']?.flow?.components?.wizard?.controlsIcons.prev,
                action: PerfumeFinderConfigFR['fr']?.flow?.components?.wizard?.props?.previousStep
              },
              {
                name: "Next",
                class: "next-btn",
                icon: PerfumeFinderConfigFR['fr']?.flow?.components?.wizard?.controlsIcons.next,
                action: PerfumeFinderConfigFR['fr']?.flow?.components?.wizard?.props?.nextStep
              }
            ]
          }
        },
        {
          name: "results",
          sections: {
            ...PerfumeFinderConfigFR['fr'].flow.components.results.sections
          },
          emailFields: {
            email: "",
            opt_in: true,
          },
          validation: yupSchemas,
          components: PerfumeFinderConfigFR['fr']?.flow?.components?.results?.subComponents,
        }
      ],
    },
  }
};
